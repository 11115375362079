import { readSessionStorageValue, useLocalStorage, useOs, useSessionStorage } from "@mantine/hooks";
import { GlobalAppsettings} from "../GlobalSettings";
import { useLanguage } from "../common/language/Language";
import { SecureTest } from "../modules/secureTest/SecureTest";
import { TestCategory } from "../common/api/TestCategory";
import { StorageKeys } from "../modules/secureTest/constants/StorageKeys";
import { FCSecureTest } from "../modules/secureTest/FCSecureTest";
import { ExternalSecureTest } from "../modules/secureTest/ExternalSecureTest";
import { useMemo, useState } from "react";

import * as App from 'pubsub-js';

export interface ExamProps { 

}

export const Exam = (props: ExamProps) => {

    const [loading, setLoading] = useState(true);

    const testCategory = useMemo<TestCategory>(() => {       
        let testCategory = readSessionStorageValue<TestCategory>({ key: StorageKeys.TestCategory, defaultValue: undefined });
        console.log("testCategory", testCategory);
        
        setLoading(false);
        return testCategory;
    }, []);

    App.publish('change.lang', { lang:  testCategory.language });

    const renderModule = () => {
        switch (testCategory?.examType) {
            case 'youcap.booking':
            case 'youcap.booking.testcenter':
                return <SecureTest  
                    timer
                    focusLock={!GlobalAppsettings.IsDevelopment}
                    //focusLockCount={GlobalAppsettings.FocusLockCount}
                    fullScreen={false}
                    testCategory={testCategory}
                    />;
            case "francecode.testcenter":
                return <FCSecureTest />
            case "youcap.external":
                return <ExternalSecureTest testCategory={testCategory}/>  
        }
    }

    return <div>
        {renderModule()}
        {/* {testCategory?.name} */}
        </div>
}