import { Paper, Stack, Button, Loader, Text, Center, Alert, Badge, Indicator } from "@mantine/core"
import { useRef } from "react";
import { useMount, useUnmount } from "../../../common/utilities/useMount";
import { useZoomVideo } from "../../../common/zoom/useZoomVideo";
import { SecureTestApi } from "../api/SecureTestApi";
import { useSecureTestLanguage } from "./useLanguage";


function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
  }

export interface ProctoringProps {
    monitoring?: SecureTestApi.Monitoring;
}

let PREVIEW_VIDEO : any;

export const Proctoring = (props: ProctoringProps) => {
    const lang = useSecureTestLanguage();
   
    const {init, videoPreview, startVideo, status, isLoading, error,  chatClient, joined, leave} = useZoomVideo();


    const initVideo = () => {
       
        console.info("Init Video from monitoring -> ", props.monitoring);
       
        if (props.monitoring!==undefined) {
            
            init({
                name: props.monitoring.sessionId,
                token: props.monitoring.proctoringCandidateToken,
                topic: props.monitoring?.id,
                password: props.monitoring.proctoringCandidatePasscode,
            });
           
        }
    }


    useMount(() => {
        
        PREVIEW_VIDEO = document.getElementById('preview-video-ref');
        
        //Todo: check video devices

        videoPreview.start(PREVIEW_VIDEO);

    });

    useUnmount(() => {
        videoPreview.stop();
        leave(true);
    });

    const getStatusColor = (status: string) => {
        
        switch (status) {
            case "connected":
                return "teal";
            case "error":
                return "red";
            default:
                return "gray";
        }
    }

    return (<>
    
    <Paper shadow={"md"} 
        // className={classNames(
        //   fullscreen ? "mx-56": "",
        //   "mt-2 mb-12 min-h-[90vh] lg:mx-20 xl:mx-40 md:mx-6 sm:mx-12"
        // )}
        className={classNames('min-h-[90vh]')}
        withBorder p={0}
    >
        {/* <Transition mounted={true} transition="slide-left" duration={400} timingFunction="ease">
        {(styles) =>  
        <div style={styles} ></div>}
        */}
        {/* }</Transition> */}
        <Stack className="p-4">
            <Indicator  color="red" processing>
                <video className="rounded-md" id="preview-video-ref"></video>
            </Indicator>
            <Button onClick={() =>  startVideo(PREVIEW_VIDEO)}>Start</Button>
            {!joined ? <Button onClick={() => initVideo()}>Join</Button>: <></>}      
            {error ? <Alert color="red" radius={"md"} variant="filled">{error}</Alert>: <></>}
            {joined ? <Button onClick={() => leave(false)}>Leave</Button>: <></>}
        
            <Center>
                {isLoading ? <Loader variant="dots" /> : <>
                </>}
            </Center>
            {chatClient ? <>
            <Stack className="h-full">
            <Text>{chatClient.getHistory().map((msg, key) => {
                return <div key={key}>{msg.sender.name}: {msg.message}</div>
            })}</Text>
            </Stack>
            </> : <></>}
        </Stack>
        <div className="fixed bottom-8">
        {status ? <Badge aria-hidden="true" color={getStatusColor(status)} size="lg" variant="filled">{status}</Badge>: <></>}
        </div>
    </Paper>
    </>
    )
}