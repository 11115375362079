import { Center, ScrollArea, SimpleGrid, Stack, Text, Image, Group, Grid, GridCol } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ContentItem } from "../../common/content/api/Content";
import { useContent } from "../../common/content/useContent";
import { useLanguage } from "../../common/language/Language";

import {default as LogoWhiteBlue} from '../../common/logos/Logo_whiteblue';

export interface HeroProps {
    children?: any
    defaultImageUrl?: string,
    content?: ContentItem
    showLogo?: boolean
    smallContent?: boolean
}

export const Hero = (props: HeroProps) => {
    const lang = useLanguage();
    
    const isLargeScreen = useMediaQuery('(min-width: 1200px)');

    const url = props.defaultImageUrl ?  props.defaultImageUrl : "https://images.ctfassets.net/2b5s0apidvye/1fllYdGvftt9Ylz3GQ7Xn7/04d79e32152109df988041dbc7bcedb4/60bede999448454d9a8f9ad1_levi-stute-mFF39sOZSgM-unsplash.jpg";
    
    return (<>
    <Grid className='h-[100vh] w-[100vw] bg-gray-900 overflow-hidden'
        >
          <Grid.Col span={isLargeScreen ? 6 : 12}>
            <Image 
                  h={isLargeScreen ? "100vh": 150}
                  className="inset-0 h-full w-full"
                  fit="cover"
                  src={props.content!==undefined && props.content.media !==null && props.content.media !==undefined ?  props.content?.media?.url : url}
                  
              />
          </Grid.Col>
          <Grid.Col span={isLargeScreen ? 6 : 12}>
            <Stack  className={isLargeScreen ? 'px-8 py-4' : 'px-6'} >
              <ScrollArea  
                  offsetScrollbars 
                  className={isLargeScreen ? 'h-[95vh]':'h-[90vh]'}
                  >
                  {props.children}
              </ScrollArea>
              {props.showLogo &&
              <Group justify="center" className={isLargeScreen ? 'fixed left-0 right-0 bottom-5 h-8': 'fixed left-0 bottom-5 right-0 h-8'}>
                <Center className='pb-6'>
                <a href="https://www.youcap.com">
                        <Text size='xs' className="text-gray-500">Powered By</Text>
                        <LogoWhiteBlue style={{width: '100px', paddingLeft: '7px'}}/>
                    </a>
                </Center>
                {/* <Center className="text-slate-500 text-xs pb-4">
                    Copyright 2024 YouCap Ltd.
                </Center> */}
              </Group>}
            </Stack> 
          </Grid.Col>
      </Grid>
    </>)
}