import { Button, Drawer,  Group, ScrollArea, Stack, Title } from "@mantine/core";
import { useDisclosure, useForceUpdate, useMediaQuery } from "@mantine/hooks";
import { useState } from "react";

import { useLanguage } from "../language/Language";
import { useSubscribe } from "../pubsub/usePubsub";


export const InfoDrawer = (props: {title?: any, position?: any, 
        className?: string, children?: any, buttons?: any, message: string, contentClassName?: string,
        callToAction?: any, closeMessage?: string}) => {
    const lang = useLanguage();
    const [opened, { open, close }] = useDisclosure(false, {
        onClose: () => {
            
        }
    });
    const forceupdate = useForceUpdate();
   

    const isMobile = useMediaQuery('(max-width: 768px)');
    const isLargeScreen = useMediaQuery('(min-width: 1200px)');
    
    useSubscribe(props.message, () => {
        open();
    });

    
    useSubscribe(props.closeMessage ? props.closeMessage: props.message + '.close', () => {
        close();
    });
    

    return (
    <Drawer
        opened={opened} 
        position={props.position}
        size={isMobile ? '100%' : isLargeScreen? '800px' : '80%'}
        padding={isMobile ? 'sm' : 'lg'}
        onClose={() => close()}
        className={props.className}
        title={props.title}
        overlayProps={{opacity: 0.2}}
        closeButtonProps={{style: {width: 40, height: 40}}}
        keepMounted={true}
        //overlayProps={{ opacity: 0.5, blur: 4 }}
    
        >
    <Stack justify="space-between"  className={props.contentClassName ? props.contentClassName: 'h-[90vh] px-6'} >
        <ScrollArea  offsetScrollbars>
            {props.children}
        </ScrollArea>
        <Group className='py-6' align='stretch'>    
            {props.callToAction}
            {props.buttons ? props.buttons: 
                <Button variant="outline" onClick={()=> close()}>{lang.Text('Close')}</Button>
            }
        </Group>
        </Stack>
    </Drawer>
    )
}