import { useCallback, useEffect, useState, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { usePubsub, useSubscribe } from "../pubsub/usePubsub";
import { GlobalAppsettings } from "../../GlobalSettings";
import { Organisation, UserProfile, UserResponse } from "../api/UserProfile";

import { useNavigate } from "react-router-dom";

import useSWR from "swr";
import { fetcher, Http } from "../Fetcher";
import {  useLocation } from "react-router-dom";
import { IntercomPubsubKeys } from "../intercom/Intercom";
import { ResponseData } from "../api/common";
import { useForceUpdate } from "@mantine/hooks";

import * as App from 'pubsub-js';

export interface fetchInterface {
  url: string;
  method: string;
  accessToken: string;
}

export const GlobalSecurity = () => {

  const { loginWithRedirect, isLoading: isAuth0Loading,
          user, error, logout, getAccessTokenSilently } = useAuth0();
  // const app = usePubsub();

  const [isLoading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState<any>(undefined);
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [userProfile, setProfile] = useState<UserProfile | undefined>(undefined);
  const [organisations, setOrganisations ] = useState<Organisation[] | undefined>(undefined);
  const [organisation, setOrganisation] = useState<Organisation | undefined>(undefined);
 
  const [currentOrganisationId, setCurrentOrganisationId] = useState<string | undefined>(undefined);

  // useSubscribe('global.login',() => {
  //  _login();
  // });

  useEffect(() => {
    if (userProfile !== undefined) {
      App.publish(IntercomPubsubKeys.SetupUser, {
        name: userProfile.name,
        userid: userProfile.id,
        email: userProfile.email
      });
    } 
  }, [userProfile]);

  const getAccessToken = useCallback(async (scope: string = "read:user") => { 
    console.info("getAccessToken ->", scope);
    var token =  await getAccessTokenSilently({
      authorizationParams: { scope: scope, audience: GlobalAppsettings.Audience}
    })
    return token;
  }, [getAccessTokenSilently])

  useEffect(() => {
    getAccessToken().then((token) => {
      setAccessToken(token);
      setLoggedIn(true);
    })
    .catch((error) => {
      setLoading(false);
      setLoginError(true);
      console.error("getAccessToken->",error);
      //_login();
    });
  }, [getAccessToken] );

  useEffect(() => {
    let loading = false;
    loading = isAuth0Loading;
    if (user!==undefined && !isLoggedIn) {
      loading = true;
    }
    if (loginError){
      loading = false;
    }
    setLoading(loading);
  }, [user, isLoading, isAuth0Loading, isLoggedIn]);

  const hasModule = (moduleId: string): boolean => {
    if (organisation?.modules!==undefined) {
      let module = organisation.modules?.find(x => moduleId.toLowerCase().startsWith(x.identifier.toLowerCase()));
      if (module !== undefined) {
        console.info('Module found: ' + moduleId);
        return true;
      }
    }
    return false;
  }

  const navigate = useNavigate();
  const location = useLocation();

  const { data, mutate: refreshUserInfo } = useSWR<UserResponse>(
    accessToken!==undefined ? ["/api/user/me", accessToken] : null,
    () => fetcher("/api/user/me", Http.GET, accessToken),
    {
       refreshInterval: 0,
       onError: (error) => {
          //console.error("auth ->", error);
          //if (error.status === 401) {
            getAccessToken().then((token) => {
              setAccessToken(token);
              setLoggedIn(true);
            });
          //}
       }
   });

  const _userProfile = useMemo(() => {
      if (data!==undefined && data.success)
      {
        //console.log('update ->', data);
        setProfile(data.result.user);
        setOrganisations(data.result.otherOrganisations);
        setCurrentOrganisationId(data.result.user.currentOrganisationId);
        
                  
        setLoggedIn(true);
        setLoading(false);

        if (data.result.organisation !== undefined)
          {
            try {
                setOrganisation(data.result.organisation);
              } catch (error) {
                console.error(error);
              }
          }
          else
          {
              console.info("No organisation found, goto invitation page");
              if (location.pathname!=='/invitation')
                window.open('/invitation', '_self');
          }
        
        return data.result.user;
      }
   },[data, location]);

   

  const _login = (path?: string) => {
    try {
      if (path === undefined) path = '';
      
      //window.alert("login ->"+ window.location.origin + path);
      
      loginWithRedirect({ authorizationParams: { redirect_uri: window.location.origin + path} })
        .catch((error) => {
          console.error("login->",error);
        });
    } catch (error) {
       setLoading(false);
    }
  }

  const getCurrentOrganisation = () => {
    var org = organisations?.find(x => x.id === _userProfile?.currentOrganisationId);
    return org;
  }

  const _logout = (path?:string) => {
    if (path === undefined) path = '';
    logout({ logoutParams: { returnTo: window.location.origin + path } });
  }
  
  const forceUpdate = useForceUpdate();

  const switchOrganisation = (organisationId: string, gotoPage: string | undefined) => {
    if (accessToken === undefined) return;
    if (organisationId === undefined) return;
    if (userProfile===undefined) return;
    if (currentOrganisationId === organisationId){
      if (gotoPage!==undefined) navigate(gotoPage); 
      return;
    }    
    var param = {
      newOrganisationId: organisationId
    };

    fetcher<ResponseData<UserProfile>>(
        GlobalAppsettings.BaseUrl + '/api/user/changeMyCurrentOrganisation',
        Http.POST,
        accessToken,
        param
    )
    .then((data: ResponseData<UserProfile>) => {
        if(data !== undefined){
          if(data.isValid === true && data.success === true){
              setCurrentOrganisationId(organisationId);  
              if(data.data.currentOrganisationId!==undefined) {
                  refreshUserInfo();
                  console.info("Switched to organisation: " + organisationId);
                  forceUpdate();
                
                  if (gotoPage!==undefined) {
                     navigate(gotoPage); 
                  }
                  else
                  {
                  }
                  App.publish('global.organisationChanged', data.data.currentOrganisationId);
              }            
          }
          else
          {  
            console.error(data.errorMessage);
          }
        }
    }).catch((error) => {
        console.error(error);
    });

  }

 
  return {    
    isLoggedIn,
    isLoading,
    accessToken,
    getAccessToken,
    userInfo: user,
    userProfile,
    login: (path?: string)=> _login(path),
    logout: (path?: string) => _logout(path),
    organisation: organisation,
    hasModule: hasModule,
    hasOrganisation: organisation !== undefined,
    hasInvite: userProfile?.hasInvite === true,
    organisations: organisations,
    refresh: refreshUserInfo,
    switchOrganisation: switchOrganisation ,
    currentOrganisationId: currentOrganisationId,
    getCurrentOrganisation,
  }

}





