import { TestCategory } from "../../../common/api/TestCategory";
import { ContentItem } from "../../../common/content/api/Content";

export module SecureTestApi {
 
    export interface SessionPackage {
        session:            Session;
        success:            boolean;
        errorMessage:       string;
        messageLanguageKey: string;
        testCategory:       TestCategory;
    }
    
    export interface Session {
        
        examUrl:        string;
        id:             string;
        area:           string;
        orgid:          string;
        examTitle: string;
        examLogoUrl: string;
        testCategoryId: string;
        code:           string;
        
        onboardingId:     string;
        isOnboarding: boolean;
        onboardingUrl:  string;

        monitoringId:     string;
        exam:           Exam;
        language:       Language;
        timeStampUtc:   string;
        isRunning:      boolean;
        isError:        boolean;
        isErrorFatal:   boolean;
        message:        string;
        durationInMinutes: number;

        token?: string
    }

    export interface Exam {
        id:              string;
        version:         string;
        testCategoryId:  string;
        copyright:       Copyright;
        area:            string;
        orgid:           string;
        title:           string;
        description:     string;
        questions:       Question[];
        time:            Time;
        isFinished:      boolean;
        answerCount:     number;
        totalCount:      number;
        passCount:       number;
        timeStartedUtc:  string;
        timeFinishedUtc: string;
        testCenterId:    string;
        proctoring:      Proctoring;
        status:          number;
        result:          any;
        logoUrl:       string;
    }

    export interface FranceCodeSession {
        
        examUrl:        string;
        id:             string;
        area:           string;
        orgid:          string;
        examTitle: string;
        examLogoUrl: string;
        testCategoryId: string;
        code:           string;
        
        onboardingId:     string;
        isOnboarding: boolean;
        onboardingUrl:  string;

        monitoringId:     string;
        exam:           FranceCodeExam;
        language:       Language;
        timeStampUtc:   string;
        isRunning:      boolean;
        isFinished:      boolean;
        isError:        boolean;
        isErrorFatal:   boolean;
        message:        string;
        durationInMinutes: number;
        
        token?: string
    }

    export interface FranceCodeExam { 

        id:                  string;
        area:                string;
        drawId:              string;
        neph:                string;
        bookingId:           string;
        testCenterId:        string;
        passed:              boolean;
        tasks:               FCQuestion[];
        questions?:          FCQuestion[];
        eulaSigned:          boolean;
        tutorialStarted:     boolean;
        startExamPrompted:   boolean;
        testCompleted:       boolean;
        testStarted:         boolean;
        waitForResult:       boolean;
        viewedResult:        boolean;
        flaggedForSuspicion: boolean;
        testStartDateTime:   string;
        testEndDateTime:     string;
        connectivityError:   boolean;
        error:               boolean;
        errorMessage:        string;
        currentClientId:     string;
        status:              string;
        progress:            string;
        macAddress:          string;
        drawNumber:          number;
        isNew:               boolean;
        code:                string;
    }
        
    export interface FCQuestion {
        id:         string;
        name:       string;
        fileRef:    string;
        blob?: string; 
        a_Checked:   boolean;
        b_Checked:   boolean;
        c_Checked:   boolean;
        d_Checked:   boolean;
        answerDate?: string;
        isAnswered: boolean;
    }

    
    
    export interface Copyright {
        body:        string;
        legal:       string;
        description: string;
    }
    
    export interface Proctoring {
        flaggedForSuspicion: boolean;
        reason:              string;
        proctoringType:      string;
    }
    
    export interface Question {
        id:                         string;
        version:                    string;
        index:                      number;
        text:                       string;
        questionType:               QuestionType;
        isAnswered:                 boolean;
        timeAnswered:               string;
        groupDescription:           string;
        competenceGroup:            string;
        competenceGroupDescription: string;
        answers:                    Answer[];
        answerCount:                number;
    }
    
    export interface Answer {
        id:      string;
        index:   number;
        text:    string;
        prefix:  Prefix;
        checked: boolean;
    }
    
    export enum Prefix {
        A = "A",
        B = "B",
        C = "C",
        D = "D",
    }
    
    export enum QuestionType {
        YoucapMultiplechoice = "youcap.multiplechoice",
        YoucapSinglechoice = "youcap.singlechoice",
    }
    
    export interface Time {
        hours:          number;
        minutes:        number;
        extraMinutes:   number;
        isExtraMinutes: boolean;
    }
    
    export interface Language {
        languageId: string;
        locale:     string;
        name:       string;
        keywords:   Keyword[];
    }
    
    export interface Keyword {
        key:   string;
        value: string;
    }
    
    export interface Monitoring {
        id:     string;
        sessionId: string;
        orgid:  string;
        events: MonitoringEvent[];
        isFlagged: boolean;
        flaggedReason: string;
        isFinished: boolean;
        isStarted: boolean;
        isRemoteProctoring: boolean;
        proctoringToken: string;
        proctoringCandidateToken: string;
        proctoringCandidatePasscode: string;
        timeLeft: number;
        shouldCloseExam: boolean;
        closeReason: string;
    }

    export interface MonitoringEvent {
        id:          string;
        eventType: string;
        eventTypeCode: MonitoringEventTypeCode;
        eventText: string;
        eventTime: string;
        data:       any;
    }

    export enum MonitoringEventTypeCode {
        Internal = 1,
        Start = 4,
        Exam = 5,
        End =6,
        Proctor = 8,
        System = 7,
    }
    
    export interface Onboarding {
        id:                   string;
        purchaseId:           string;
        purchaseOrgId:        string;
        purchaseBookingId:    string;
        testTemplateId:       string;
        verifications:        any[];
        identityId:           string;
        cameraCaptureDataUri: string;
        isValid:              boolean;
        notValidReason:       string;
        isError:              boolean;
        errorReason:          string;
        isCompleted:          boolean;
        steps:                OnboardingStep[];
    }

    export interface OnboardingStep {
        Id: string;
        contentRef: string;
        isCompleted: boolean;
        isValid: boolean;
        reason: string;
        errorMessage: string;
        order: number;
        stepType: OnboardingStepType;
        contentItem: ContentItem;
        data: string;
        settings: OnboardingStepSetting[]; 
        link: string;
        isProcessing: boolean;
    }

    export interface OnboardingStepSetting {
        key: string;
        value: any;
    }


    export enum OnboardingStepType {
        Content = 1,
        YouCapID = 2,
        Selfie = 3,
        Passport = 4,
        Video = 5,
        Document = 6,
        Code = 7,
        Eula = 8,
        Identity = 9,
        RemoteProctoring = 10,
        ProctorUnlock = 11,
        SelfStart = 12,
        TalentLmsTraining = 13,
        FranceCodeTuturial = 14,
    }

    export interface Result {
        id:              string;
        date:            string;
        answerCount:     number;
        unAnswerCount:   number;
        areas:           Areas;
        correctCount:    number;
        passed:          boolean;
        isCertification: boolean;
        isAssessment:    boolean;
        assesmentLevel:  number;
        score:           number;
        testId:          string;
        title:           string;
        identity:        string;
        email?:          string;
        remainTestTime:  number;
        isTimeEnded:     boolean;
        errorMessage:    string;
        firstName:       string;
        lastName:        string;
    }
    
    export interface Areas {
        [key: string]: Area;
    }

    export interface Area {
        id:              string;
        title:           string;
        answerCount:     number;
        unAnswerCount:   number;
        correctCount:    number;
    }

}


