import { useForceUpdate, useFullscreen, useLocalStorage } from "@mantine/hooks";
import { useState } from "react";
import { useSecureTestLanguage } from "./components/useLanguage";
import { SecureTestApi } from "./api/SecureTestApi";
import { StorageKeys } from "./constants/StorageKeys";
import { TestCategory } from "../../common/api/TestCategory";
import { useFingerPrint } from "../../common/fingerprint/useFingerPrint";

export interface IExternalTestProps {
    id?: string
    url?: string
    timer?: boolean
    scrollLock?: boolean
    fullScreen?: boolean
    focusLock?: boolean
    focusLockCount?: number
    testCategory?: TestCategory
  }


export const ExternalSecureTest = (props: IExternalTestProps) => {
    const lang = useSecureTestLanguage();

  const [loading, setLoading] = useState(true);
  const forceUpdate = useForceUpdate();

  const { fingerprint } = useFingerPrint();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { toggle: toggleFullScreen, fullscreen } = useFullscreen();
  const [fullscreenMode, setFullscreenMode] = useState<boolean | undefined>(props.fullScreen);

  const [currentQuestionIndex, setCurrentIndex] = useState(1);

    /*   
  currentSession is stored in Localstorage
  */
  const [currentSession, setCurrentSession] = useLocalStorage<SecureTestApi.Session | undefined>(
    {key: StorageKeys.Session, defaultValue: undefined});

    return (
        <div>
            <h1>External secure test</h1>
            
        </div>
    );
}