import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import ZoomContext from './context/ZoomContext';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import produce from 'immer';
import { ChatClient, CommandChannelClient } from "./index-types";

declare global {
    interface Window {
        WebVideoSDK?: any;
        
        zmClient: any | undefined;
        mediaStream: any | undefined;
        crossOriginIsolated: boolean;
    }
}

/*
  Try to preload dependent assets
*/
// ZoomVideo.preloadDependentAssets();

export interface ZoomVideoParams {
    topic: string;
    token: string;
    name: string;
    password?: string;
}

const mediaShape = {
    audio: {
      encode: false,
      decode: false
    },
    video: {
      encode: false,
      decode: false
    },
    share: {
      encode: false,
      decode: false
    }
  };

  const mediaReducer = produce((draft, action) => {
    switch (action.type) {
      case 'audio-encode': {
        draft.audio.encode = action.payload;
        break;
      }
      case 'audio-decode': {
        draft.audio.decode = action.payload;
        break;
      }
      case 'video-encode': {
        draft.video.encode = action.payload;
        break;
      }
      case 'video-decode': {
        draft.video.decode = action.payload;
        break;
      }
      case 'share-encode': {
        draft.share.encode = action.payload;
        break;
      }
      case 'share-decode': {
        draft.share.decode = action.payload;
        break;
      }
      case 'reset-media': {
        Object.assign(draft, { ...mediaShape });
        break;
      }
      default:
        break;
    }
  }, mediaShape);
  

/**
    From https://github.com/zoom/videosdk-web-sample/blob/master/react-demo/src

    This is a custom hook that will initialize the Zoom Video SDK and return the
    client object. It will also handle the connection state changes and reconnect
    the client if the connection is lost.

    The hook will return the following values:
    - zmClient: The Zoom Video SDK client object
    - isLoading: A boolean value indicating if the client is still loading
    - error: An error message if there is an error
    - mediaStream: The media stream object
    - isSupportGalleryView: A boolean value indicating if the current browser supports gallery view
    - chatClient: The chat client object
    

 */

export const useZoomVideo = () => {
    const zmClient = useContext(ZoomContext);

    const [initParams, setInitParams] = useState<ZoomVideoParams |undefined>(undefined);

    const [isLoading, setLoading] = useState(true);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [mediaStream, setMediaStream] = useState<any>();
    const [isSupportGalleryView, setIsSupportGalleryView] = useState(false);
    const [chatClient, setChatClient] = useState<ChatClient>();

    const [isFailover, setIsFailover] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('closed');
    const [joined, setJoined] = useState<boolean>(false);

    const [audioTrack] = useState(ZoomVideo.createLocalAudioTrack())
    const [videoPreview] = useState(ZoomVideo.createLocalVideoTrack());

    const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
    const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);

    // const [commandClient, setCommandClient] = useState<CommandChannelClient>();
    // const [recordingClient, setRecordingClient] = useState<Re>();
    // const [subsessionClient, setSubsessionClient] = useState<any>();
    // const [liveTranscriptionClient, setLiveTranscriptionClient] = useState<any>();

    useEffect(() => {
        const init = async () => {
          setError(undefined);
          
          //videoPreview.stop();

          await zmClient.init('en-US', "CDN", {
            webEndpoint: 'zoom.us',
            enforceMultipleVideos: true,
            stayAwake: true
          });
          try {
            //setLoadingText('Joining the session...');
            if (initParams!==undefined && !joined) {
                await zmClient.join(
                        initParams.topic, 
                        initParams.token, 
                        initParams.name, 
                        initParams.password
                        )
                .catch((e) => {
                    setLoading(false);
                    setError(e.reason);
                    console.error(e);
                });

                const stream = zmClient.getMediaStream();

                setMediaStream(stream);
                setIsSupportGalleryView(stream.isSupportMultipleVideos() && !isAndroidBrowser());
                
                const chatClient = zmClient.getChatClient();
                // const commandClient = zmClient.getCommandClient();
                // const recordingClient = zmClient.getRecordingClient();
                //const ssClient = zmClient.getSubsessionClient();
                // const ltClient = zmClient.getLiveTranscriptionClient();
                
                setChatClient(chatClient);
                //setSubsessionClient(ssClient);
                // setCommandClient(commandClient);
                // setRecordingClient(recordingClient);
                // setLiveTranscriptionClient(ltClient);

                setLoading(false);
                
            }
          } catch (e: any) {
            setLoading(false);
            console.error(e);
            setError(e.reason);
          }
        };
        init();
        return () => {
          ZoomVideo.destroyClient();
        };
      }, [initParams]);

    const initZoomVideo = (params: ZoomVideoParams) => {
        console.info("Init Zoom Video ->", params);
        setInitParams(params);
    }

    const startVideo = async (element: HTMLVideoElement) => {
      if (mediaStream!==undefined) {
        try {
          await mediaStream.startVideo({
            videoElement: element
          }).catch((e: any) => {
            console.error(e);
            setError(e.reason);
          });
        } catch (error) {
          console.log(error);
        }
      }
    };

    const leave = (host: boolean | undefined) => {
        zmClient.leave(host).catch((e) => {
          console.error(e);
        });
    }

    const onConnectionChange = useCallback(
        (payload: any) => {
          if (payload.state === ConnectionState.Reconnecting) {
            setLoading(true);
            setIsFailover(true);
            setStatus('connecting');
            const { reason, subsessionName } = payload;
            if (reason === ReconnectReason.Failover) {
              setMessage('Session Disconnected,Try to reconnect');
            } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
                setMessage(`Joining ${subsessionName}...`);
            } else if (reason === ReconnectReason.BackToMainSession) {
                setMessage('Returning to Main Session...');
            }
          } else if (payload.state === ConnectionState.Connected) {
            setStatus('connected');
            setJoined(true);
            if (isFailover) {
              setLoading(false);
            }
            window.zmClient = zmClient;
            window.mediaStream = zmClient.getMediaStream();
    
            console.log('getSessionInfo', zmClient.getSessionInfo());
          } else if (payload.state === ConnectionState.Closed) {
            setStatus('closed');
            setJoined(false);
            dispatch({ type: 'reset-media' });

            if (payload.reason === 'ended by host') {
            //   Modal.warning({
            //     title: 'Meeting ended',
            //     content: 'This meeting has been ended by host'
            //   });
            }
          }
        },
        [isFailover, zmClient]
      );

    useEffect(() => {
        zmClient.on('connection-change', onConnectionChange);
        // zmClient.on('media-sdk-change', onMediaSDKChange);
        // zmClient.on('dialout-state-change', onDialoutChange);
        // zmClient.on('merged-audio', onAudioMerged);
        return () => {
          zmClient.off('connection-change', onConnectionChange);
        //   zmClient.off('media-sdk-change', onMediaSDKChange);
        //   zmClient.off('dialout-state-change', onDialoutChange);
        //   zmClient.off('merged-audio', onAudioMerged);
        };
      }, [zmClient, onConnectionChange, 
        // onMediaSDKChange, onDialoutChange, onAudioMerged
        ]);

    return {
        init: initZoomVideo,
        videoPreview,
        startVideo,
        isLoading: isLoading,
        error: error,
        status: status,
        mediaStream: mediaStream,
        isSupportGalleryView: isSupportGalleryView,
        chatClient: chatClient,
        joined,
        leave: leave,
    }
}



export class _localZoomVideo {
    public static createClient(): any {
        /*
        from https://marketplace.zoom.us/docs/sdk/video/web/get-started/
        */

        let sdk = window.WebVideoSDK;
        let client = sdk.createClient();
        client.init('en-US', 'CDN');

        global.window.zmClient = client;

        console.info("Zoom Video Client ->", client);

       return client;
    }
}



// eslint-disable-next-line import/prefer-default-export
export function getExploreName() {
    const { userAgent } = navigator;
    if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
      return 'Opera';
    }
    if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1) {
      return 'IE';
    }
    if (userAgent.indexOf('Edge') > -1) {
      return 'Edge';
    }
    if (userAgent.indexOf('Firefox') > -1) {
      return 'Firefox';
    }
    if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
      return 'Safari';
    }
    if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1) {
      return 'Chrome';
    }
    if (!!(window as any).ActiveXObject || 'ActiveXObject' in window) {
      return 'IE>=11';
    }
    return 'Unkonwn';
  }
  
  export function isSupportWebCodecs() {
    return typeof (window as any).MediaStreamTrackProcessor === 'function';
  }
  const isIPad = () => {
    return /MacIntel/i.test(navigator.platform) && navigator?.maxTouchPoints > 2;
  };
  export const isIOSMobile = () => {
    const { userAgent } = navigator;
    const isIOS = /iPad|iPhone|iPod/i.test(userAgent);
    return isIOS || isIPad();
  };
  
  export function isAndroidBrowser() {
    return /android/i.test(navigator.userAgent);
  }
  export function isAndroidOrIOSBrowser() {
    return isAndroidBrowser() || isIOSMobile();
  }
  export function isSupportOffscreenCanvas() {
    return typeof (window as any).OffscreenCanvas === 'function';
  }