import { useEffect, useRef, useState } from "react";
import { useSubscribe } from "../../common/pubsub/usePubsub";
import { Box } from "@mantine/core";

export interface VideoPlayerProps {
    id?: string
    className?: string
    src?: string
    active?: boolean
    play?: boolean
    onEnded?: () => void
    onPlaying?: () => void 
    height?: number
    width?: number
}

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
  }
  
export interface VideoVolumeData {
    value: number
}


export const VideoPlayer = (props : VideoPlayerProps) => {
    const [videoSource, setVideoSource] = useState<string | undefined>(props.src)
    const vidRef = useRef<HTMLVideoElement>(null);

    const posterImage = "";

    useSubscribe('video.volume', (msg: string, data: VideoVolumeData) => {
        if (data!==undefined && !(data.value > 1.0) && !(data.value < 0.0)) {
            let vp = vidRef.current;
            if (vp!==null) {
                vp.volume = data.value;
            }
        }
    });

    useEffect(() => {
        setVideoSource(videoSource);
    }, [props.src])



    useEffect(() => {
        if (vidRef.current !== null) {
            try {
                vidRef.current.muted = false;
                props.play ? vidRef.current.play() : vidRef.current.pause();    
            } catch (error) {
                console.error('Error playing video', error);   
            }
        }
    }, [videoSource, props.play, vidRef])

    return (<Box>
    <div className={classNames('pointer-events-none', props.active ? 'block' : 'hidden')}>
        <video 
            className={props.className}
            height={props.height}
            width={props.width}
            muted={true} 
            autoPlay={props.play} 
            controls={false}
            ref={vidRef}
            loop={false}
            src={videoSource}
            disablePictureInPicture={true}
            onWheel={(e) => { e.preventDefault(); e.stopPropagation(); }} //fix for zooming in and out
            onPlaying={(e) => {
                console.log('Playing', e);
                props.onPlaying?.call(props.id);
            }}
            onEnded={(e) => {
                console.log('Ended', e);
                props.onEnded?.call(props.id);
            }}
            poster={posterImage}
        >
            {/* <source src={videoSource} type="video/mp4" /> */}
        </video>
    </div></Box>)
}