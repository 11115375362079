import { PropsWithChildren } from "react";
import { GlobalSecurity } from "../GlobalSecurity";
import { Alert, Loader, Title, Text, Group, Center, Button, Stack } from "@mantine/core";

import { useLanguage } from "../../language/Language";


export interface AuthenticatedContentProps {
  children: PropsWithChildren<any>;
  path?: string;
  isLoading?: boolean;
}

export const AuthenticatedContent = (props: AuthenticatedContentProps) => {
    const { isLoggedIn, login, isLoading, hasOrganisation, hasInvite } = GlobalSecurity();
    const lang = useLanguage();
    // const about = useContent({contentID: 'Nc9LkLhmzFg8fQKZq1Cg1', locale: lang.locale(), preview: false});
    // const app = usePubsub();
    
    if (isLoading) {
      return <Center className='h-[50vh]'><Loader ></Loader></Center>;
    }

    if (isLoggedIn && hasOrganisation) {
      return <>{props.children}</>;
    }

    return (<div className="min-h-[80vh] text-center py-32 px-32">
      <Center className='h-[20vh]'><Loader ></Loader></Center>
      
      {props.isLoading ? 
      <Button size="lg" onClick={() => login(props.path)} >{lang.Text('Login')}</Button>
      : <></>}
    
    </div>);
};