import { readSessionStorageValue, useForceUpdate, useFullscreen, useHotkeys, useLocalStorage, useMediaQuery, useViewportSize } from "@mantine/hooks";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { SecureTestApi } from "./api/SecureTestApi";
import { StorageKeys } from "./constants/StorageKeys";
import { TestCategory } from "../../common/api/TestCategory";
import { Alert, Button, Center, Grid, Group, LoadingOverlay, Paper, Text, Title, Image, Loader, Stack, Progress, Card, Modal, Box, Switch, Checkbox, RingProgress } from "@mantine/core";

import {default as LogoWhiteBlue} from '../../common/logos/Logo_whiteblue';


import { fetcher, Http } from "../../common/Fetcher";
import { LanguageInfo } from "../../common/language/LanguageControl";

import * as App from 'pubsub-js';
import { VideoPlayer } from "../video/VideoPlayer";

import { GlobalAppsettings } from "../../GlobalSettings";
import { useLanguage } from "../../common/language/Language";
import { DateTime } from "luxon";
import { GlobalSecurity } from "../../common/security/GlobalSecurity";

import { useMonitoring } from "./components/useMonitoring";
import { AuthenticatedContent } from "../../common/security/components/AuthenticatedContent";
import { useNavigate } from "react-router-dom";
import { PrefecthResult, useVideoStore } from "../video/useVideoStore";
import { useFingerPrint } from "../../common/fingerprint/useFingerPrint";
import { current } from "immer";

export interface IFranceCodeTestProps {
    id?: string
    url?: string
    timer?: boolean
    scrollLock?: boolean
    fullScreen?: boolean
    focusLock?: boolean
    focusLockCount?: number,
    // testCategory?: TestCategory
  }

function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
  }
  
interface LoaderStatus {
  ref: string
  loaded: boolean
  error?: boolean
  errorText?: string
}

export const FCSecureTest = (props: IFranceCodeTestProps) => {
  
  const lang = useLanguage();
  const [langIsUpdated, setLangIsUpdated] = useState(false);

  const [loading, setLoading] = useState(true);
  
  const [prefetching, setPrefetching] = useState(false);

  const [ready, setReady] = useState(false);
  
  const { fingerprint } = useFingerPrint();

  const navigate = useNavigate();

  const {isLoggedIn, isLoading, accessToken, userInfo} = GlobalSecurity();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [answerA, setAnswerA] = useState(false);
  const [answerB, setAnswerB] = useState(false);
  const [answerC, setAnswerC] = useState(false);
  const [answerD, setAnswerD] = useState(false);

  const [examEnded, setExamEnded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [stored, setStored] = useState(false);
  /*   
    currentSession is stored in storage
  */
  const [currentSession, setCurrentSession] = useLocalStorage<SecureTestApi.FranceCodeSession | undefined>(
        {key: StorageKeys.Session, defaultValue: undefined, serialize: useCallback((value: any) => JSON.stringify(value), []),});

  
  const [currentQuestionIndex, setCurrentIndex] = useState(0);

  const [preLoadCount, setPreLoadCount] = useState(0);

  const [preLoadError, setPreLoadError] = useState(false);

  const [playing, setPlaying] = useState(false);

  const [volume, setVolume] = useState(1.0);

  const [questions, setQuestions] = useLocalStorage<SecureTestApi.FCQuestion[]>({ key: StorageKeys.Exam, 
      defaultValue: undefined, serialize: useCallback((value: any) => JSON.stringify(value), []), });

  const { height, width } = useViewportSize();

  useEffect(() => {
    if (!isLoggedIn) {
      console.info("not logged in ->", );
      //login();
    }
    else
    {
      console.info("logged in ->", userInfo?.email);
      clearPreCache();
      setPrefetching(true);
    }
  }, [isLoggedIn, accessToken]);
  
  const preFetchDone = (result: PrefecthResult) => {
    console.info("prefetch result ->", result);
    if (result.hasError)
    {
      setPreLoadError(true);
    }
    else
    {
        //go thru the questions and update fileRef
        setQuestions(questions => 
          questions.map((item) => {
            let blob = getPreCache(item.id);
            console.info("update question ->",item.id, " ->", blob);
            if (blob!==undefined) item.blob = blob;
            return item;
          })
        );
    }     
  }
  
  useEffect(() => {
      console.info("prefetching ->", prefetching);
      if (prefetching) {
        if (currentSession!==undefined && isLoggedIn) {
      
          setLoading(true);
          setPreLoadError(false);
          setReady(false);
          

          let newList = currentSession.exam.tasks.map((item) => {
            item.name = item.fileRef;
            let blobRef = getPreCache(item.fileRef);
            if (blobRef === undefined) {
              addPreCache(item.id, getMediaUrl(item.fileRef));
            }
            return item;
          });

          setQuestions(newList);
          
          setPreLoadCount(0);
          prefetch(preFetchDone);
          
        }
      }
  }, [prefetching]);

  const reportFileLoaded = (fileRef: string, blobRef: string) => {
      console.info("file loaded ->", fileRef, " -> ", blobRef);
      setPreLoadCount(preLoadCount => preLoadCount + 1);
      
  }

  const retryLoad = () => {
    setPreLoadError(false);
    setPreLoadCount(0);
    prefetch(preFetchDone);

    // Refresh browser window
    window.location.reload();
  }

  useEffect(() => {
    if (preLoadCount === totalQuestions) {
      setLoading(false);
    }
  }, [preLoadCount]);

  const { addPreCache, getPreCache , prefetch, clearPreCache, count, total} = useVideoStore({accessToken: accessToken, 
    onMediaLoaded: reportFileLoaded, 
    onError: (error) => {
      console.error("error prefetching video:" , error);
    }
  });

  const totalQuestions = currentSession?.exam.tasks.length ?? 0;

  /* hotkeys */
  useHotkeys([
    ['a', () => setAnswerA(!answerA)],
    ['z', () => setAnswerA(!answerA)],
    ['b', () => setAnswerB(!answerB)],
    ['x', () => setAnswerB(!answerB)],
    ['c', () => setAnswerC(!answerC)],
    ['d', () => setAnswerD(!answerD)],
    ['v', () => setAnswerD(!answerD)],
  ]);

const volumeUp = () => {
  setVolume(volume => {
    if (volume<1.0) {
      return volume + 0.1;
    }
    else
    {
      return volume;
    }
  });
}

const volumeDown = () => {
  setVolume(volume => {
    if (volume>0.0) {
      return volume - 0.1;
    }
    else
    {
      return volume;
    }
  });
}

useEffect(() => {
  console.info("volume ->", volume);
  App.publish('video.volume', { value: volume });
}, [volume]);

const getMediaUrl = (fileRef: string) => {
  return GlobalAppsettings.BaseUrl + '/api/securetest/getmedia?id=' + fileRef;
}

const {startMonitoring, stopMonitoring, 
  addEvent } = useMonitoring(
{
  refreshInterval: 20000, 
  monitoringType: 'monitoring',
  timer: false,
  endExam: () => setExamEnded(true),
  token: currentSession?.token,
});

const collectAnswers = () => {  
  
  //console.info("collectAnswers for index:" , currentQuestionIndex);

  //update monitoring
  addEvent("answer", "Answered question " + (currentQuestionIndex+1), 
    { 
      questionId: questions[currentQuestionIndex].id,
      answerA: answerA,
      answerB: answerB,
      answerC: answerC,
      answerD: answerD,
    }, 1);

  let currentQuestionId = questions[currentQuestionIndex].id;

  //update questions 
  setQuestions(questions => {
    let newList = questions.map((item) => {
      if (item.id === currentQuestionId) {
        item.a_Checked = answerA;
        item.b_Checked = answerB;
        item.c_Checked = answerC;
        item.d_Checked = answerD;
        item.answerDate = DateTime.now().toISO();
        item.isAnswered = true;
      }
      return item;
    });
    return newList;
  });

  setAnswerA(false);
  setAnswerB(false);
  setAnswerC(false);
  setAnswerD(false);
}

const gotoNextQuestion = () => {
  if (currentQuestionIndex < totalQuestions-1) {
    collectAnswers();
    setCurrentIndex(currentQuestionIndex+1);
  }
  else
  {
    collectAnswers();
    if (currentQuestionIndex===totalQuestions-1) {
      setExamEnded(true);
    }
  }
}

const startExam = () => {
  setCurrentSession(currentSession => {
    if (currentSession!==undefined ) {
      
      console.info("Start Exam...");
      currentSession.isRunning = true;
      currentSession.exam.testStarted = true;
      currentSession.exam.testCompleted = false;
      currentSession.exam.testStartDateTime = DateTime.now().toISO();
      setReady(true);
      setPlaying(true);
      
      console.info("startMonitoring:" , currentSession.monitoringId);
      startMonitoring(currentSession.monitoringId, currentSession.durationInMinutes * 60);

      addEvent("exam", "Start Exam", "", SecureTestApi.MonitoringEventTypeCode.Internal);
    }
    console.info("updated currentSession:" , currentSession);
    return currentSession;
  });
}

const saveExam = () => {
  if (examEnded) {
    setSaving(true);

    fetcher(GlobalAppsettings.BaseUrl + '/api/securetest/PostExamSecure',Http.POST, 
      currentSession?.token
      //accessToken
      , currentSession, false, fingerprint)
          .then((response) => {
        if (response.success) {
          console.info("exam stored:" , response.result);
          setError(false);
          setErrorMessage(undefined);
          setStored(true);

          addEvent("exam", "Exam stored","", SecureTestApi.MonitoringEventTypeCode.End);
          stopMonitoring();
        }
        else
        {
          console.error("error storing exam:" , response);
          
          addEvent("exam", "Error storing exam >" + response.errorMessage);

          setError(true);
          setSaving(false);
          setErrorMessage(response.errorMessage);
        }
      }).catch((error) => {
        console.error("error storing exam:" , error);
        setError(true);
        setErrorMessage(error);
        setSaving(false);
        addEvent("exam", "Error storing exam > " + error);
      });
  }
}

useEffect(() => {
  // console.info("examEnded ->", examEnded);
  if (examEnded) {
    //store answers
    console.info("store answers");
    console.info("stored questions:" , questions);

    setCurrentSession(currentSession => {
      if (currentSession!==undefined) {
        
        // currentSession.exam.tasks = questions.map((item) => {
        //   let task: SecureTestApi.FCQuestion = {
        //     id: item.id,
        //     fileRef: item.fileRef,
        //     a_Checked: item.a_Checked,
        //     b_Checked: item.b_Checked,
        //     c_Checked: item.c_Checked,
        //     d_Checked: item.d_Checked,
        //     answerDate: item.answerDate,
        //     isAnswered: item.isAnswered,
        //   }
        //   return task;
        // });

        currentSession.exam.questions = questions.map((item) => {
          let task: SecureTestApi.FCQuestion = {
            id: item.id,
            name: item.name,
            fileRef: item.fileRef,
            a_Checked: item.a_Checked,
            b_Checked: item.b_Checked,
            c_Checked: item.c_Checked,
            d_Checked: item.d_Checked,
            answerDate: item.answerDate,
            isAnswered: item.isAnswered,
          }
          return task;
        });
        
        currentSession.isRunning = false;
        currentSession.isFinished = true;
        currentSession.exam.status = "finished";
        currentSession.exam.testCompleted = true;
        currentSession.exam.testEndDateTime = DateTime.now().toISO();
      }
      console.info("updated currentSession:" , currentSession);
      return currentSession;
    });

    //send session to server
    saveExam();
  }

}, [examEnded]);




useEffect(() => {
  // console.info("stored ->", stored);
  if (examEnded && stored) {
    console.info("exam stored and ended");
    //clear sessions
    clearPreCache();
    setCurrentSession(undefined);
    setQuestions([]);

    //redirect to end page
    navigate('/secure/' + currentSession?.testCategoryId);
  }
}, [stored]);


return (<>
  <AuthenticatedContent isLoading={isLoading}>
  <Grid 
    // grow 
    // gutter={10} 
    // columns={24} 
  className="overflow-hidden">
  {/* Main */}
  <Grid.Col span='auto'>
  <div>
      <Paper shadow="md" 
          className={classNames(
            "mb-12 mx-1 xl:min-w-[1200px] md:p-12 z-50 rounded-md bg-gray-900"
          )}
          component="div"
           p={0}
          >
        <div className="pb-4 min-h-[90vh]" style={{ position: 'relative' }}>
 
        {/* Loading */}
        <Modal opened={!ready} centered
           withCloseButton={false} onClose={() => setLoading(false)} 
            title={loading ? <Text className="font-bold text-lg">{lang.Text('Loading Exam...')}</Text> 
                            : <Text className="font-bold text-lg">{lang.Text('Ready to start!')}</Text>} 
            size={560}      
            className=""
            transitionProps={{ transition: 'fade', duration: 200 }}
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 5,
            }}
            closeOnClickOutside={false}
            closeOnEscape={false}
            lockScroll={true}
            >
          <Box className="p-12">
              <Group justify="space-between">
                {loading ? 
                <RingProgress
                  sections={[{ value: Math.round(preLoadCount/totalQuestions * 100), color: preLoadError ? 'red' :'blue'}]}
                  label={
                  <Text c="blue" fw={700} ta="center" size="xl">
                    {Math.round(preLoadCount / totalQuestions * 100)}%
                  </Text>
                }
              /> : <>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="green" className="w-16 h-16">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                </>}
                {preLoadError ? <Button color="red" size="lg" onClick={retryLoad}>{lang.Text('Retry')}</Button>: 
                <Button onClick={() =>{
                  startExam();
                }}
                disabled={loading}
                color="green"
                size="lg"
                >{lang.Text('I am ready')}</Button>}
              </Group>
          </Box>
        </Modal>
  
        {/* Error */}
        {error ? <Alert className='bg-red-200 m-6'
                    icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                  } 
                    title={<Title order={4}>{lang.Text('Something went wrong')}</Title>} color="red" radius="md">
                    <Text>{errorMessage}</Text>
            </Alert> : <></>}
        { examEnded && error ? <>
          <Group justify="center" className="mt-12">
            <Button onClick={() =>{
              saveExam();
            }}
            disabled={saving}
            color="green"
            size="lg"
            >{lang.Text('Retry')}</Button>

            <Button variant="subtle" onClick={() => {
              setCurrentSession(undefined);
              setQuestions([]);
              navigate('/secure/' + currentSession?.testCategoryId);
            }}>{lang.Text('Reset')}</Button>
          </Group>
        </>:<></>}

         {/* Exam Ended*/}
         <Modal opened={saving} centered
           withCloseButton={false} onClose={() => setLoading(false)} 
            title={<Text className="font-bold text-lg">{lang.Text('Storing Exam...')}</Text>} 
            closeOnClickOutside={false}
            closeOnEscape={false}
            size="lg">
          <Box className="p-12">
            <Stack justify="center">
              {saving ? <Loader /> : <></>}
            </Stack>
          </Box>
        </Modal>
          

        {currentSession!==undefined && !examEnded ? 
        <>
        {/* Header */}
        <Group justify="space-between" className="py-2 sm:px-6 px-2 md:px-6 shadow-sm bg-gradient-to-r from-[#2677ea] to-[#072eba]">
        <Image
            w={height<900 ?  200 : 400}
                // radius="lg"
                fit="contain"
                src={currentSession?.examLogoUrl}
                alt="logo"
            />
            <Group>
              {/* Volume Controls */}
              <Button variant="subtle" onClick={() => volumeDown()}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
              </svg>
              </Button>
              <Button variant="subtle" onClick={() => volumeUp()}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z" />
              </svg>
              </Button>
            </Group>
            <LanguageInfo/>
        </Group>
        <div className={classNames('bg-black pb-4 pt-1 max-h-[80vh]', loading ? 'hidden': 'block')}>
        {ready && questions?.map((question, index) => {
          return <Center>
          <VideoPlayer className="" 
            width={width < 1200 ? width :width > 2200 ? width - 900 : width > 1900 ? width - 700 : width > 1600 ? width - 400 : width > 1400 ? width - 250 : width -150}
            active={currentQuestionIndex===index} 
            key={index} 
            src={question.blob} 
            play={playing && currentQuestionIndex===index} 
            // preLoad={true} 
            // onMediaLoaded={() => {
            //   reportFileLoaded(question.fileRef);
            // }} 
            onEnded={() => {
              gotoNextQuestion();
            }}/></Center>
        })}
        </div>
        {loading ? <></> : <>
        <Group justify="space-between" className="py-12 px-6 bg-black text-white">
          <Group>
              <Text size="lg">{currentQuestionIndex+1}/{totalQuestions}</Text>
          </Group>
          <Group className="">
           
            <Checkbox labelPosition="left" label="A" size="40px" radius={"md"} checked={answerA} onChange={(event:any) => setAnswerA(event.currentTarget.checked)}/>
            <Checkbox labelPosition="left" label="B" size="40px" radius={"md"} checked={answerB} onChange={(event:any) => setAnswerB(event.currentTarget.checked)}/>
            <Checkbox labelPosition="left" label="C" size="40px" radius={"md"} checked={answerC} onChange={(event:any) => setAnswerC(event.currentTarget.checked)}/>
            <Checkbox labelPosition="left" label="D" size="40px" radius={"md"} checked={answerD} onChange={(event:any) => setAnswerD(event.currentTarget.checked)}/>
          </Group>
          <Group>
              <Text size="sm">{currentSession.exam.tasks[currentQuestionIndex].name}</Text>
          </Group>
        </Group>
         </>}
      </>: <></>}
      </div>
    </Paper>
    </div>
  </Grid.Col>


  </Grid>


  {/* id & neph */}
  <div className="fixed left-2 bottom-0 h-6 pointer-events-none z-1 text-xs text-gray-500">
      {currentSession?.id} - {currentSession?.exam.neph} - {fingerprint}
  </div>
  {/* Footer */}
  <div className={'fixed left-8 bottom-0 right-0 h-16 pointer-events-none z-1'}>
  <Center className='pb-6'>
  <a href="https://www.youcap.com">
          <Text size='xs' className="text-gray-500">Powered By</Text>
          <LogoWhiteBlue style={{width: '100px', paddingLeft: '7px'}}/>
      </a>
      
  </Center>
 
  </div>
  </AuthenticatedContent>
  </>
  );

}

