import { useLocalStorage } from "@mantine/hooks";
import { useLanguage } from "../common/language/Language";
import { TestCategory } from "../common/api/TestCategory";
import { StorageKeys } from "../modules/secureTest/constants/StorageKeys";
import { useContentItem } from "../common/content/useContent";
import { useEffect, useState } from "react";
import { ContentItem } from "../common/content/api/Content";
import { useNavigate } from "react-router-dom";
import { GlobalSecurity } from "../common/security/GlobalSecurity";

import * as App from 'pubsub-js';
import { Center, Loader, Alert, Group, Text, Title, Button } from "@mantine/core";
import { LanguageInfo } from "../common/language/LanguageControl";
import { AuthenticatedContent } from "../common/security/components/AuthenticatedContent";
import { Hero } from "./components/Hero";
import { renderMarkdown } from "../common/content/RenderMarkdown";

export const EndExam = () => {
    const lang = useLanguage();

    const navigate = useNavigate()

    const { isLoading, isLoggedIn, accessToken } = GlobalSecurity();

    const [loading, setLoading] = useState(true);

    const [currentTestCategory, setCurrentTestCategory] = useLocalStorage<TestCategory>({ key: StorageKeys.TestCategory, defaultValue: undefined });
    const [content, setContent] = useState<ContentItem | undefined>(undefined);

    const { getContentItem } = useContentItem('endexam.content');

    const [error, setError] = useState<string>('');


    useEffect(() => {
        if (currentTestCategory!==undefined) {
            
            if (currentTestCategory.language !== undefined && currentTestCategory.language !== lang.locale()) {
                App.publish('change.lang', { lang: currentTestCategory.language });
            }
        
            getContentItem(currentTestCategory?.contentRef, ).then((result) => {
                setContent(result);
            }).catch((error) => {
                console.error(error);
                setError(lang.Text('Something went wrong. Please try again later.'));
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [currentTestCategory, getContentItem, lang]);

    return (
        <AuthenticatedContent>
        {loading ? <Center className='h-[50vh]'><Loader variant='dots'></Loader></Center> : <>
        {error ? <Alert className='bg-red-200 m-8'>{error}</Alert>:
        <Hero content={content} showLogo>
        <Group justify='space-between' preventGrowOverflow wrap="nowrap">
            <Text className='text-lg text-slate-400'>{currentTestCategory.description}</Text>
            <LanguageInfo/>
        </Group>
        <div className='pt-8 '>
            {renderMarkdown(content?.description,'','text-slate-100 font-light')}
        </div>

        {/* <Button>{lang.Text}</Button>     */}
        </Hero>}
        </>}
      </AuthenticatedContent>
    );  

}