//import 'sentry.js';

import React from "react";

// Packages imports
import { BrowserRouter } from "react-router-dom";
import { MantineProvider, ColorSchemeScript } from "@mantine/core";



import { SWRConfig } from 'swr';

import ZoomContext from './common/zoom/context/ZoomContext';

// Components imports
import App from "./App";

// Providers imports
import { LanguageProvider } from "./common/language/Language";

// Backbone imports
import { GlobalAppsettings, sessionStorageCacheProvider } from "./GlobalSettings";

import { fetcher, Http } from "./common/Fetcher";
import { NativeClient } from "./modules/nativeClient/interop";

// Matine default styles
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';

// Styles imports
import "./styles/preflight.css";
import "./styles/index.css";
import "./styles/fonts.css";

import { Auth0Provider } from "@auth0/auth0-react";
import { createRoot } from 'react-dom/client';

import ZoomVideo from '@zoom/videosdk';




// init zoom sdk
const zmClient = ZoomVideo.createClient();


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain="youcap.eu.auth0.com"
            clientId="hbXX0MwyINx0XZ3AF4CWhtLbUVhNz9cl"
            redirectUri={window.location.origin}
            audience="https://api.portal.youcap.com"
            scope= "openid profile email read:user"
      >
    <SWRConfig
      value={{
        fetcher: fetcher,
        //provider: localStorageProvider
        provider: sessionStorageCacheProvider
      }}
    >
    <LanguageProvider>
            <ColorSchemeScript defaultColorScheme="auto" />
            <MantineProvider
              theme={{
                defaultColorScheme: "auto",
                fontFamily: "Poppins, sans-serif",
                fontFamilyMonospace: "Monaco, Courier, monospace",
                headings: {
                  fontFamily: 'Poppins, sans-serif',
                  sizes: {
                    h1: { fontSize: "3.75rem", fontWeight: "800" },
                  },
                breakpoints: {
                    xs: '30em',
                    sm: '48em',
                    md: '64em',
                    lg: '80em',
                    xl: '96em',
                  },
                },
                black: "#454545",
              }}
            >
         <ZoomContext.Provider value={zmClient}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
            </ZoomContext.Provider>
        </MantineProvider>
    </LanguageProvider>
    </SWRConfig>
    </Auth0Provider>
    </React.StrictMode >
);


new NativeClient();