import * as App from 'pubsub-js';

declare global {
    interface Window {
        chrome?: any;
    }
}

export interface NativeMessage {
    id: string
    message: string
    data: any
}

export class NativeClientPubsubKeys {
    static Send: string = "native.client.send";
    static Receive: string = "message";
    static State: string = "state";
}

export interface NativeState {
    isLocked: boolean;
    hasProfile: boolean;
    currentProfile: ClientProfile;
}

export interface ClientProfile {
    id: string
    orgId: string
    description: string
    status: string
    version: string
    error: string
    newVersionUri: string
    shouldUpdate: boolean
}

export class NativeClient {
    constructor() {
        if (window.chrome && window.chrome.webview) {
            window.chrome.webview.addEventListener(NativeClientPubsubKeys.Receive, this.messageHandler);
        }
        App.subscribe(NativeClientPubsubKeys.Send,this.sendMessage);
    }

    private sendMessage(msg: string, data: any) {
        if (window.chrome && window.chrome.webview) {
            window.chrome.webview.postMessage(data);
        }
    }

    public static SendMessage(data: any) {
        console.info("Sending message to native client", data);
        if (window.chrome && window.chrome.webview) {
            window.chrome.webview.postMessage(data);
        }
    }

    private messageHandler(message: NativeMessage) {
        let data = JSON.parse(message.data);
        console.info("Received message from native client", data);
        App.publish(data.message, data.data)
    }
}

export const useNativeClient = () => {
    const showFullScreen = () => {
        NativeClient.SendMessage({ message: "securetest.fullscreen.enter" });
    }

    const exitFullScreen = () => {
        NativeClient.SendMessage({ message: "securetest.fullscreen.exit" });
    }

    const showDevTools = () => {
        NativeClient.SendMessage({ message: "securetest.opendevtools", data: null });
    }

    const saveData = (id: string, data: any) => {
        NativeClient.SendMessage({ message: "securetest.save", id: id, data: data });
    }

    const loadData = (id: string) => {
        NativeClient.SendMessage({ message: "securetest.load", id: id });
    }

    const showPanel = () => {
        NativeClient.SendMessage({ message: "securetest.showpanel"});
    }

    const login = () => {
        NativeClient.SendMessage({ message: "securetest.login"});
    }

    const checkState = () => {
        NativeClient.SendMessage({ message: "securetest.check"});
    }

    const registerClient = (orgId: string, description: string) => {
        NativeClient.SendMessage({ message: "securetest.register", data: { orgId: orgId, description: description }});
    }

    return {
        showFullScreen,
        exitFullScreen,
        showPanel,
        showDevTools,
        saveData,
        loadData,
        login,
        registerClient,
        checkState
    }
}