import { Center, Group, Stack, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { ActionButton } from "../common/content/components/ActionButton";

import { renderMarkdown } from "../common/content/RenderMarkdown";
import { useContent } from "../common/content/useContent";
import { useLanguage } from "../common/language/Language";
import { Hero } from "./components/Hero";



export const Result = () => {
    const lang = useLanguage();

    const isMobile = useMediaQuery('(max-width: 768px)');
    const smallDevice = useMediaQuery('(max-width: 1220px)');
    const isLargeScreen = useMediaQuery('(min-width: 1200px)');

    const hero = useContent({contentID: '3PTC1LGANtWFYqKyen4Wnv', locale: lang.locale(), preview: false});
    const info = useContent({contentID: '4jmSDRXlnkgMoTZSkC8coo', locale: lang.locale(), preview: false});

    return (<>
    <Hero content={hero.contentItem}>

    <Stack className={isLargeScreen ? 'max-w-xl text-white  py-8 px-4' 
            : isMobile ? 'max-w-xl text-white py-8 px-4' : 'max-w-2xl text-white py-8 px-20'}
        >

        <Title className="text-5xl">{info.contentItem?.caption} </Title>

        <div className='pt-8'>
            {renderMarkdown(info.contentItem?.copy,'','text-white font-extralight')}
        </div>

        <Group justify="center" >
            {info.contentItem?.actions.map((item, index) => (
                <ActionButton key={index} action={item}/>)
            )}
        </Group>
    </Stack>

    </Hero>
    </>)
}