
import { useEffect, useState } from "react";
import { SecureTestApi } from "../api/SecureTestApi";


export interface useLangProps {
    lang: SecureTestApi.Language
}

export function useSecureTestLanguage(props?: useLangProps) {
    const [lang, setLang] = useState<SecureTestApi.Language | undefined>(props ? props?.lang: undefined);
    const [locale, setLocale] = useState<string>();
    const [langId, setLangId] = useState<string>();

    useEffect(() => {
        if (lang!==undefined){
        setLocale(lang.locale);
        setLangId(lang.languageId);
      }
    },[lang]);

    const Text = (search: string): string => {
        let val: string = search;
    
        if (lang!==undefined && lang.keywords!==undefined && search !== undefined && search !== null) {
            let found = false;
            

            lang.keywords.forEach((element) => {
                if (element.key.toLowerCase() === search.toLowerCase()) {
                    val = element.value;
                    found = true;
                    return val;
                }
            });
            if (!found) {
                return search;
            }
        }
        else {
            return 'empty.value';
        }
        return val;
    }

    const Load = (lang: SecureTestApi.Language) => {
       setLang(lang);
    }

    return {
         Text,
         Load,
         locale,
         langId         
    }

}
