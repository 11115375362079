import { List, TypographyStylesProvider } from "@mantine/core";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

export const renderMarkdown = (markdown: any, key?: string, className?: any, linkcolor?: any) => {
    return(<>
       {markdown ?
       <TypographyStylesProvider p={0}>
           <ReactMarkdown className={className} key={key} 
                children={markdown}  
                remarkPlugins={[remarkGfm, remarkBreaks]}
                components={{
                    li: ({node, ...props}) => {
                        return <li className={className} {...props}/>;
                    },
                    // ul: ({node, ...props}) => {
                    //     return <List {...props} icon={<FontAwesomeIcon color="#0476d9" size="1x" icon={duotone('check-circle')}/>}/>;
                    // },
                    a: ({node, ...props}) => {
                        return <a {...props} target="_blank" rel="noreferrer" style={{color:linkcolor, textDecoration: 'underline'}} >{props.children}</a>;
                    }
                }}
            />
       </TypographyStylesProvider>:<></>}
       </>);
   }