

import { Title, Group, FileButton, Button, Center, Loader, Image, Text, Stack, Alert, ScrollArea, Paper, Card, Spoiler, Modal } from "@mantine/core";
import { useMediaQuery, useViewportSize } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";

import { ActionButton } from "../../common/content/components/ActionButton";

import { renderMarkdown } from "../../common/content/RenderMarkdown";
import { useContent } from "../../common/content/useContent";
import { fetcher, Http } from "../../common/Fetcher";
import { useLanguage } from "../../common/language/Language";
import { InfoDrawer } from "../../common/panels/InfoDrawer";
import { usePubsub } from "../../common/pubsub/usePubsub";
import { SecureTestApi } from "../secureTest/api/SecureTestApi"

import QRCode from "react-qr-code";

import { VideoPlayer } from "../video/VideoPlayer";
import { useFingerPrint } from "../../common/fingerprint/useFingerPrint";

export interface OnboardingStepProps {
   step:  SecureTestApi.OnboardingStep
   children?: React.ReactNode;
   onboardingId: string;
   token?: string;
   reset: () => void;
   next: () => void;
   retry: () => void;
}

export const OnboardingStep = (props: OnboardingStepProps) => {
    const lang = useLanguage();
    const app = usePubsub();

    const { fingerprint } = useFingerPrint();

    const content = useContent({contentID: props.step.contentRef, locale: lang.locale(), preview: false});
    
    const [loading, setLoading] = useState(false);

    const { height, width } = useViewportSize();
        
    const [file, setFile] = useState<File | null>(null);
    useEffect(() => {
        if (file) {
            //TODO: upload file
            console.info("File uploaded: ", file);
            
            //TODO: update onboarding
            //mutate(onboarding, false);
            //updateStep()
        }
    },[file]);

    const updateStep = (data: any) => {
        if (props.onboardingId!==undefined) {
            setLoading(true);
            let step = props.step;
            switch (step.stepType) {
                case SecureTestApi.OnboardingStepType.Content:
                    break;
                default:
                    break;
            }

            console.info("Update step: ", step, data);
            //console.info("token: ", props.token);
            console.info("fingerprint: ", fingerprint);

            fetcher<SecureTestApi.Onboarding>('/api/securetest/updatestep' , 
                Http.POST, props.token, {
                    onboardingId: props.onboardingId,
                    step: step,
                    data: JSON.stringify(data)}, false, fingerprint)
            .then((result) => {
                app.publish('onboarding.reload', result);
                setLoading(false);
            });
        }
    }
    
    const renderOnboardingStep = (step: SecureTestApi.OnboardingStep) => {
        switch (step.stepType) {
            case SecureTestApi.OnboardingStepType.Content:
                return <></>;
            case SecureTestApi.OnboardingStepType.Code:
                return <></>;
            case SecureTestApi.OnboardingStepType.Identity:
                return <>
                <Group className="px-4" justify="center">
                    <Paper radius="xl" shadow="md" withBorder className="p-6 max-w-[250px] text-center">
                        <Center>
                            <Stack className="">
                                <QRCode size={200}
                                    value={step.link}
                                />
                                <Text size={"sm"} className="p-2">{lang.Text('Scan to identify on your mobile device')}</Text>
                            </Stack>
                        </Center>
                    </Paper>
                    <Center className="py-2">
                        <Button leftSection={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                            </svg>
                            } 
                            color="green" size="lg" component="a" target={"_new"} href={step.link}>
                            <Text >{lang.Text('Open in a new window')}</Text>
                        </Button>
                    </Center>
                </Group>
                <Center>
                    {step.isProcessing  && step.errorMessage ? <>
                        
                    </>:<></>}
                </Center>
                </>;
            case SecureTestApi.OnboardingStepType.YouCapID:
                return <></>;
            case SecureTestApi.OnboardingStepType.Video:
                return <>
                <Modal 
                    centered 
                    opened={true} 
                    onClose={() => updateStep({skip: true})}
                    withCloseButton={false}
                    closeOnEscape 
                    radius={0}
                    // fullScreen
                    withinPortal
                    size={height+200}
                    overlayProps={{
                        backgroundOpacity: 0.55,
                        blur: 3,
                      }}

                    styles={{
                        body: {
                          backgroundColor: 'black',
                        },
                    }}
                    >
                    <Modal.Body>
                    <Stack justify="center">
                     <Center>
                        <VideoPlayer id={props.onboardingId} 
                            play={true} 
                            //src={content.contentItem?.media?.url} 
                            src={"https://videos.ctfassets.net/2b5s0apidvye/5BWRYjyDdfQQiUDlKbsiIz/a75a747b086b3ba43745e9a08fa45d48/Tutoriel_examens_FC.mp4"}
                            active={true}
                            // preLoad={true}
                            // onMediaLoaded={() => console.info("Video loaded")}
                            onEnded={() => {
                                updateStep({skip: true});
                            }}
                        />
                    </Center>
                    <Group justify="right" className="pt-8 px-8">
                        <Button color={'green'} loading={loading} onClick={()=> updateStep(
                            {
                                skip: true
                            }
                        )} size="lg">{lang.Text('Skip Tutorial')}</Button>
                    </Group>
                    </Stack>
                    </Modal.Body>
                </Modal>
                </>;
            case SecureTestApi.OnboardingStepType.Passport:
                return <></>;
            case SecureTestApi.OnboardingStepType.Selfie:
                return <></>;
            case SecureTestApi.OnboardingStepType.Eula:
                return <>
                <Center className="pt-2">
                    <Button color={'green'} loading={loading} onClick={()=> updateStep(
                        {
                            accepted: true
                        }
                    )} size="lg">{lang.Text('Accept Terms and Conditions')}</Button>
                </Center>
                </>;
            case SecureTestApi.OnboardingStepType.RemoteProctoring:
                return <></>;
            case SecureTestApi.OnboardingStepType.ProctorUnlock:
                return <></>;
            case SecureTestApi.OnboardingStepType.SelfStart:
                return <>
                <Group  justify="center" className="mt-12">
                    <Button size="lg" color="green" loading={loading}
                        onClick={()=> updateStep(
                            {
                                start: true
                            })}
                        >{lang.Text('Start Exam')}</Button>
                </Group>
                </>;
            case SecureTestApi.OnboardingStepType.TalentLmsTraining:
                return <>
                    <Loader variant="dots" size={"xl"}></Loader>
                 </>; 
            case SecureTestApi.OnboardingStepType.FranceCodeTuturial:
                return <>
                    <Loader variant="dots" size={"xl"}></Loader>
                    <Center className="pt-2">
                        <Button color={'green'} loading={loading} onClick={()=> updateStep(
                            {
                                accepted: true
                            }
                        )} size="lg">{lang.Text('Next')}</Button>
                    </Center>
                 </>;                
        }
        return <></>;
    }

    

    return (<Stack className="">
        
        {props.step.isValid ? <>
        <Center><Loader /></Center>
        </>:<>
            {content.contentItem?.copy &&
            <ScrollArea offsetScrollbars className="" >
            <Spoiler maxHeight={height - 420} showLabel={lang.Text('Show more')} hideLabel={lang.Text('Hide')}>
                {renderMarkdown(content.contentItem?.copy,'','text-white font-light')}
            </Spoiler>
            </ScrollArea>}
            <div className='py-4'>
                {renderMarkdown(content.contentItem?.slug,'','text-white font-light')}
            </div>
            <div className="py-2">
                {renderOnboardingStep(props.step)}
                {props.children}
            </div>
        </>}
        


        {props.step.reason && props.step.isValid===false ? 
        <Alert className='bg-orange-200' 
            icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
          </svg>
          } 
            title={<Title order={4}>{lang.Text('Incomplete')}</Title>} color="orange" radius="md">
            <Text>{props.step.reason}</Text>
            {/* <Button className="mt-4"
                variant='subtle' onClick={() => props.retry()}>{lang.Text('Retry')}</Button> */}
        </Alert> : <></>}
        {props.step.errorMessage && props.step.isValid===false ? 
        <Alert className='bg-red-200'
            icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
          </svg>
          } 
            title={<Title order={4}>{lang.Text('Something went wrong')}</Title>} color="red" radius="md">
            <Text>{props.step.errorMessage}</Text>
        </Alert> : <></>}

        <Group className="pt-2" justify="space-between">
            {content.contentItem?.description &&
            <Button variant="outline" onClick={() => app.publish("step.info.open",null)}>{lang.Text('More info')}</Button>}
            {content.contentItem?.actions.map((action, index) => {
                return (
                    <ActionButton key={index} action={action} />
                )
            })}
            {/* {props.step.isValid &&
            <Button variant="filled" onClick={()=> props.next()}>{lang.Text('Next')}</Button>
            } */}
        </Group>
        
        <InfoDrawer message="step.info.open">
            <div className=''>
                {renderMarkdown(content.contentItem?.description,'','text-gray-700 font-light')}
            </div>
        </InfoDrawer>
    </Stack>);
}