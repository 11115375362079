
import { Alert, Button, Center, CloseButton, Image, FileButton, Group, Input, Loader, ScrollArea, SimpleGrid, Stack, Text, TextInput, Title } from '@mantine/core';
import { useFocusTrap, useForceUpdate, useLocalStorage, useMediaQuery, useSessionStorage } from '@mantine/hooks';

import { useForm } from '@mantine/form';

import { useContent } from '../common/content/useContent';
import { useLanguage } from '../common/language/Language';
import { usePubsub } from '../common/pubsub/usePubsub';

import { renderMarkdown } from '../common/content/RenderMarkdown';
import { useCallback, useEffect, useState } from 'react';

import { GlobalAppsettings } from '../GlobalSettings';
import { SecureTestApi } from '../modules/secureTest/api/SecureTestApi';

import { StorageKeys } from '../modules/secureTest/constants/StorageKeys';
import { useNavigate } from 'react-router-dom';
import { Hero } from './components/Hero';
import { TestCategory } from '../common/api/TestCategory';

export type LandingProps = {
};

export const Public = ({}: LandingProps) => {
    const lang = useLanguage();
    const app = usePubsub();
    
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    
    const focusTrapRef = useFocusTrap();
    const [findExam, setFindExam] = useState(false);
   
    const hero = useContent({contentID: '3XiqPZxl3hDVF1bolQ9jpO', locale: lang.locale(), preview: false});
    const start = useContent({contentID: '4ehHHF0qIv1mvEgxeXiPJw', locale: lang.locale(), preview: false});
 
    const isLargeScreen = useMediaQuery('(min-width: 1200px)');
    const isMobile = useMediaQuery('(max-width: 768px)');

    const [emailError,setEmailError] = useState<string>();
    const [codeError,setCodeError] = useState<string>();

    const [error,setError] = useState<string>();

    const [currentTestCategory, setCurrentTestCategory] = useSessionStorage<TestCategory>({ 
        key: StorageKeys.TestCategory, defaultValue: undefined, serialize: useCallback((value: any) => JSON.stringify(value), []), });

    const [current, setCurrent] = useLocalStorage<SecureTestApi.Session>({ key: StorageKeys.Session, defaultValue: undefined });

    const form = useForm({
        initialValues: {  email: '', code: '', },
        validate: {
          code: (value) => (value.length < 2 ? 'Code must have at least 2 letters or numbers' : null),
          email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
      });
    
    const findExamBooking =() => {
        var validation = form.validate();

        if (!validation.hasErrors)
        {
            setLoading(true);
            console.info('Finding Exam...');
            setFindExam(true);
            setEmailError(undefined);
            setCodeError(undefined);
        }
        else
        {
            console.info(validation.errors);
            setEmailError(validation.errors.email?.toString());
            setCodeError(validation.errors.code?.toString());
        }
    }

    useEffect(() => {
        if (findExam) {
            var info = {
                identity: form.values.email,
                code: form.values.code,
                type: "youcap.booking",
                orgId: GlobalAppsettings.OrgId,
            }
            fetch(GlobalAppsettings.BaseUrl + "/api/securetest/initsession", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(info),
              })
                .then((response) => response.json())
                .then((data: SecureTestApi.SessionPackage) => {
                  console.info("Session -> ", data);
                  setLoading(false);
                  setFindExam(false);
  
                  if (data.success) {
                    setCurrent(data.session);
                    setCurrentTestCategory(data.testCategory)
                    console.info('Start onboarding...');
                    navigate(data.session.onboardingUrl);
                    setError(undefined);
                  }
                  else
                  {
                    setError(data.errorMessage);
                  }
            }).catch((error) => {
                    setFindExam(false);
                    setLoading(false);
                    setError(lang.Text("Error connecting to the SecureTest API"));
                    console.error(error);
            });
        }
    }, [findExam]);

    useEffect(() => {
        if (current!==undefined) 
        {
            console.info("Loading exam session...", current.id);
            if (current.isRunning) {
                navigate(current.examUrl);
            }            
            if (current.isOnboarding)
            {
                navigate(current.onboardingUrl);
            }
        }
    },[current]);

    return (<>
        <Hero content={hero.contentItem} showLogo>
        <Stack 
            className={isLargeScreen ? 'max-w-xl text-white  py-8 px-4' : isMobile ? 'max-w-xl text-white py-8 px-4' : 'max-w-2xl text-white py-8 px-20'}>
              {loading ? <Center className='h-[50vh]'><Loader variant='dots'></Loader></Center> : <>
                <Title className="text-5xl">{start.contentItem?.caption} </Title>
                <div className='pt-2'>
                    {renderMarkdown(start.contentItem?.copy,'','text-white font-extralight')}
                </div>
                <Input.Wrapper ref={focusTrapRef}
                    id="input-email" 
                    // withAsterisk
                    label={<span className='text-white'>{lang.Text('Email')}</span>}
                    description={<span className='text-slate-400 '>
                            {lang.Text('Enter the email you used with your booking')}</span>}
                    error={emailError}
                    >
                    <Input id="input-email" placeholder={lang.Text('Email')} {...form.getInputProps('email')} />
                </Input.Wrapper>

                <Input.Wrapper
                    id="input-code"
                    // withAsterisk
                    label={<span className='text-white'>{lang.Text('Code')}</span>}
                    description={<span className='text-slate-400 '>
                            {lang.Text('Enter the code from your booking confirmation')}</span>}
                    error={codeError}
                    >
                    <Input id="input-code" placeholder={lang.Text('Code')} {...form.getInputProps('code')} />
                </Input.Wrapper>
            
                <Group>
                    <Button color={'blue'} size={'lg'} radius={'xl'} className="my-8" onClick={()=> findExamBooking()}>
                        {lang.Text('Submit')}
                    </Button>    
                </Group>
                {error ? <Alert className='bg-red-200'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                      </svg>} 
                        title={<Title order={4}>{lang.Text('Something went wrong')}</Title>} color="red" radius="md">
                        <Text>{error}</Text>
                </Alert> : <></>}
                <div className='pt-8 '>
                    {renderMarkdown(start.contentItem?.slug,'','text-slate-100 font-extralight')}
                </div>
            </>}
        </Stack>
        </Hero>
    </> 
    );
};
