import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

// Packages imports
import { Paper, Space, Title, Image, Text, Notification, Group, Card, Badge, Button, Center, ScrollArea, Stack, TextInput, Loader } from '@mantine/core';

import { Carousel } from '@mantine/carousel';

// Components imports
import { useLanguage } from '../common/language/Language';
import { useContent } from '../common/content/useContent';

import { NativeState, useNativeClient } from '../modules/nativeClient/interop';
import { useHotkeys, useLocalStorage, useViewportSize } from '@mantine/hooks';
import { renderMarkdown } from '../common/content/RenderMarkdown';
import { GlobalSecurity } from '../common/security/GlobalSecurity';
import { ResultBase } from '../common/api/common';
import { TestCategory } from '../common/api/TestCategory';
import useSWR from 'swr';
import { fetcher, Http } from '../common/Fetcher';
import { usePubsub, useSubscribe } from '../common/pubsub/usePubsub';
import { InfoDrawer } from '../common/panels/InfoDrawer';
import { Hero } from './components/Hero';
import { UserMenu } from '../common/security/User';
import { useNavigate } from 'react-router-dom';
import { StorageKeys } from '../modules/secureTest/constants/StorageKeys';
import { SecureTestApi } from '../modules/secureTest/api/SecureTestApi';

export type TestCenterProps = {
};

export const TestCenter = ({} : TestCenterProps) => {
   const lang = useLanguage();
   const { isLoading, isLoggedIn, accessToken, login, logout, organisation, userProfile, getCurrentOrganisation } = GlobalSecurity();

   const navigate = useNavigate()

   const [registerPanel, showRegisterPanel] = useState(false);
   const [registerLoading,setRegisterLoading] = useState(false);
   
   const { showFullScreen, registerClient, checkState } = useNativeClient();

   const hero = useContent({contentID: '3PTC1LGANtWFYqKyen4Wnv', locale: lang.locale(), preview: false});
   const info = useContent({contentID: 'IZeUlM4lPD3jJlHvq45SW', locale: lang.locale(), preview: false});
   const exams = useContent({contentID: '3EOLdIIsn6wMvy2omkd1wV', locale: lang.locale(), preview: false});
   const clientInfo = useContent({contentID: '6IUaD3mZM9fpDTJeavjzja', locale: lang.locale(), preview: false});

   const [client, setClient] = useState<NativeState | undefined>();
   const [description, setDescription] = useState('');

   

   useEffect(() => {
    checkState();
   }, [isLoggedIn]);

   useSubscribe('logout', (message, data) => {
    console.log(message);  
    logout("/testcenter");
   });

   useSubscribe('state', (message, data: NativeState) => {
    console.log(data);  
    setClient(data);
   });

  
   useEffect(() => {
    if (client !== undefined)
    {
      console.log(client);
      if (!client.hasProfile && isLoggedIn){
        showRegisterPanel(true);
      }
      else
      {
         showRegisterPanel(false);
      }
      
      setRegisterLoading(false);
    }
   }, [client, client?.hasProfile]);

   const register = () => {
      if (organisation !== undefined)
      {
        setRegisterLoading(true);
        registerClient(organisation?.id, description);
      }
   }

   const [currentTestCategory, setCurrentTestCategory] = useLocalStorage<TestCategory>({ key: StorageKeys.TestCategory, defaultValue: undefined });
   
   const [categories, setCategories] = useState<TestCategory[] | undefined>();

   const { data, mutate } = useSWR<ResultBase<TestCategory[]>>(
    accessToken!==undefined ?  "/api/client/currentexams" : null,
    (url) => fetcher(url, Http.GET, accessToken), 
    {
       refreshInterval: 0,
       onError: (error) => {
          console.error(error);
       },
       onSuccess: (data) => {
        console.log(data);
       }
   });

   useEffect(() => {
    if (data?.success) {
      console.log(data.result);
      setCategories(data.result);
    }
    else
    {
      if (data!==undefined) console.log(data?.errorMessage);
    }
  }, [data]);

  useEffect(() => {
    mutate();
  }, [mutate, organisation]);

  const StartExam = (category: TestCategory) => {
    
    showFullScreen();
    if (category.testUrl !== undefined)
    {
        if (category.testUrl.startsWith('https'))
        {
          console.log('Open in window', category.testUrl);
          window.open(category.testUrl, '_self');
        }
        else
        {
          navigate(category.testUrl);
        }
    }
  }

  

  useEffect(() => {

    console.info('Clear sessions and timers');

    localStorage.removeItem(StorageKeys.Session);
    localStorage.removeItem(StorageKeys.Exam);
    localStorage.removeItem(StorageKeys.Timer);
    localStorage.removeItem(StorageKeys.Monitoring);
    localStorage.removeItem(StorageKeys.Onboarding);
    
  }, []);

  const isCategoryAvailable = (category: TestCategory): boolean => {
    let avail : boolean;
    avail = true;
    if (category.testUrl === undefined && category.testUrl === '') {
      avail = false;
    }
    if (category.testUrl === null) {
      avail = false;
    }
    if (category.available === false) {
      avail = false;
    }
    return avail;
  }

  const getImageUri = (uri: string | undefined) => {
    if (uri !== undefined) {
      return uri;
    }
    else
    return "https://images.ctfassets.net/2b5s0apidvye/7pKgsqC4csBEOX42eRxRNN/af7807f8e8a2e665deccf8ece2150105/andrew-neel-cckf4TsHAuw-unsplash__1_.jpg"
  }
  
  const { height, width } = useViewportSize();
 
  
  return (<>
      {isLoading ? <Center className='h-56'><Loader /></Center> : <>
      {isLoggedIn ? 
      <Hero content={hero.contentItem} showLogo>

      <Group className='mt-4 ' justify='space-between' preventGrowOverflow wrap="nowrap">
        <Text className='text-lg text-slate-400'>{getCurrentOrganisation()?.name}</Text>
          <UserMenu/>
      </Group>
      <div className='pt-2'>
        {renderMarkdown(exams.contentItem?.copy,'','text-white font-extralight')}
      </div>
      {categories?.length === 0 ? <div className='py-2'>
        {renderMarkdown(exams.contentItem?.description,'','text-white font-extralight')}
      </div> :
      <Carousel  
        withIndicators={categories !== undefined ? categories.length > 1 : false} 
        withControls={categories !== undefined ? categories.length > 1 : false}
        align="start" 
        w={width < 1200 ? '700' : (width/2 - 100)} 
        loop 
        slideGap={0} 
        slideSize="70%"
        >
        {categories?.map((category, key) => {return(
          <Carousel.Slide key={key}>
          <Paper
              shadow="md"
              p="xl"
              radius="md"
              style={{ backgroundImage: `url(${getImageUri(category.imageUri)})` }}
              className="h-[400px] bg-cover bg-center bg-no-repeat flex flex-col justify-between m-6 cursor-pointer bg-neutral-700 bg-blend-overlay"
              onClick={() => {
                if (isCategoryAvailable(category)) StartExam(category);
              }}
            >
              <div>
                <Text className="text-gray-200 font-light drop-shadow" size="sm">
                  {category.description}
                </Text>
                <Title order={1} className="text-white text-5xl font-extrabold drop-shadow-lg">
                  {category.name}
                </Title>
              </div>
              {/* <Button 
                  // variant="light" color="blue" 
                  className='font-light'
                  fullWidth mt="md" radius="md" 
                  disabled={!isCategoryAvailable(category)}                
                onClick={() => {
                  StartExam(category);
                }}
              >
              {lang.Text('Open')}
            </Button> */}
            </Paper>
          </Carousel.Slide>
          )})}
      </Carousel>}
      <div className='pt-4'>
        {renderMarkdown(info.contentItem?.slug,'','text-gray-300 font-light')}
      </div>
      </Hero>
      :
      <Hero content={hero.contentItem}>
        <div className='pt-8'>
          {renderMarkdown(info.contentItem?.copy,'','text-white font-light')}
        </div>
        <Center className='h-[400px]'>  
          <Button size='lg' radius={'xl'}  onClick={() => login('/testcenter')}>{lang.Text('Login')}</Button>
        </Center>
        <div className='pt-4'>
          {renderMarkdown(info.contentItem?.description,'','text-gray-300 font-light')}
        </div>
      </Hero>
      } 
      </>}

      <InfoDrawer message='register.panel' 
        className='bg-gray-800' 
        // canClose={false} 
        position='right'
        //isOpen={registerPanel}
        
        >
        <Stack className='p-6'>
            <Text>
              {renderMarkdown(clientInfo.contentItem?.copy,'','text-gray-300 font-light')}
            </Text>
            <TextInput className='my-4'
              placeholder="description"
              label="Description"
              value={description} 
              onChange={(event) => setDescription(event.currentTarget.value)}
            />
            <Button className='my-4' loading={registerLoading}
             onClick={() => register()}>{lang.Text('Register')}</Button>
            <Text className='pt-2'>
              {renderMarkdown(clientInfo.contentItem?.slug,'','text-gray-300 font-extralight')}
            </Text>
        </Stack>
      </InfoDrawer>
    </> 
    );
};
