import { useLocalStorage, useSessionStorage } from "@mantine/hooks";
import { useCallback, useEffect, useState } from "react";

import axios from 'axios';


export interface PrefetchItem {
    id?: string
    url?: string
    blob_url?: string
    isLoaded?: boolean
    isError?: boolean
    error?: string
}

export interface VideoStoreProps {
    onMediaLoaded?: (fileRef: string, blobRef: string) => void
    accessToken?: string
    onDone?: () => void
    onError?: (error: any) => void
}

export interface PrefecthResult {
    total: number
    count: number
    hasError: boolean
    error?: string
    errorCount: number
}

export const useVideoStore = (props: VideoStoreProps) => {

    const [loadCount, setCount] = useState<number>(0);

    //const [count, setCount] = useState<boolean>(false);
    // const [prefetchCache, setPrefetchCache] = useSessionStorage<PrefetchItem[]>({ key: '_prefetchCache', defaultValue: [],
    //     serialize: useCallback((value: any) => JSON.stringify(value), [])
    // });

    const [prefetchCache, setPrefetchCache] = useState<PrefetchItem[]>([]);

    useEffect(() => {
        return () => {
            clear();
        }
    }, []);

    const clear = () => {
        console.log('Cleaning up prefetch cache...');
            
            //cleanup
            prefetchCache.forEach((item: PrefetchItem) => {
                if (item.blob_url !== undefined && item.blob_url !== '') {
                    URL.revokeObjectURL(item.blob_url);
                }
            });
            
            setPrefetchCache([]);
    }

    const add = (id: string, url: string) =>{
        let item = prefetchCache.find((item: PrefetchItem) => item.id === id);
        if (item === undefined) {
            // add item to prefetch cache
            let newItem = {
                id: id,
                url: url,
                blob_url: '',
                isLoaded: false,
                isError: false,
                error: ''
            }

            console.log('Adding to prefetch cache ->', newItem);
            prefetchCache.push(newItem);
           
            setPrefetchCache([...prefetchCache]);
        }
    }

    const prefetch = (onDone?: (result: PrefecthResult) => void) => {
        console.log('Prefetching all videos...', prefetchCache);
        //prefetch all that are not loaded
        let count = 0;
        let errorCount = 0;
        let hasError = false;
        prefetchCache.forEach(async (item: PrefetchItem) => {
            if (!item.isLoaded) {
                await prefetch_video2(item.url || '').then((blob_url: string) => {
                    item.blob_url = blob_url;
                    item.isLoaded = true;
                    setPrefetchCache([...prefetchCache]);
                    if (props.onMediaLoaded!==undefined) {
                        props.onMediaLoaded(item.id || '', blob_url);
                    }
                    count++;
                }).catch((error: string) => {
                    item.isError = true;
                    item.error = error;
                    hasError = true;
                    count++;
                    errorCount ++;
                    setPrefetchCache([...prefetchCache]);
                });
            }
            else
            {
                if (props.onMediaLoaded!==undefined) {
                    props.onMediaLoaded(item.id || '', item.blob_url || '');
                }
                count++;
            }
            setCount(count);
            if (count===prefetchCache.length)
            {
                let result = {
                    total: prefetchCache.length,
                    count: count,
                    hasError: hasError,
                    errorCount: errorCount
                }
                if (onDone!==undefined) onDone(result);
            }
        });
    }

    const get = (id: string) : string | undefined => {
        let item = prefetchCache.find((item) => item.id === id);        
        return item?.blob_url || undefined;
    }

    const prefetch_video = async (url: string): Promise<string> =>{
        return new Promise<string>((resolve, reject ) => {
            try {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url, true);
                console.log('Prefetching video ->', props.accessToken);
                if (props.accessToken !== undefined) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + props.accessToken);
                }
                xhr.responseType = "blob";
                xhr.timeout = 80000; // 80 seconds for timeout 

                xhr.onload = function (e: any) {
                    if (xhr.status === 200) {
                        var URL = window.URL || window.webkitURL;
                        var blob_url = URL.createObjectURL(xhr.response);
                        resolve(blob_url);
                    }
                    else {
                        console.log(xhr.statusText);
                        reject(xhr.statusText);
                    }
                };

                xhr.onerror = function (e: any) {
                    reject("Error -> " + e);
                };

                xhr.ontimeout = function (e: any) {
                    reject("Timeout -> " + xhr.status);
                };

                xhr.send();
            } catch (error) {
                reject(error);
            }
        });
    }

    const prefetch_video2 = async (url: string): Promise<string> =>{
        return new Promise<string>((resolve, reject ) => {
            try {
                axios({
                    url: url, 
                    method: 'GET',
                    responseType: 'blob', // important
                    
                    headers: {
                        'Authorization': 'Bearer ' + props.accessToken,
                        'Content-Type': 'application/octet-stream',
                        'Access-Control-Allow-Origin' : '*',    
                    },
                    timeout: 120000, // 2 minutes
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity
                }).then((response: any) => {
                    const blob_ref = URL.createObjectURL(response.data);
                    resolve(blob_ref);
                }).catch((error: any) => {
                    console.error('Error prefetching video ->', error);
                    reject(error);  
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    return {
        addPreCache: add,
        getPreCache: get,
        prefetch,
        clearPreCache: clear,
        count: loadCount,
        total: prefetchCache.length
    }

}