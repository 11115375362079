import { DateTime, Duration } from "luxon";
import { useEffect, useState } from "react";
import { fetcher, Http } from "../../../common/Fetcher";

import { SecureTestApi } from "../api/SecureTestApi";
import useSWR from "swr";
import { useLocalStorage, useInterval, useOs } from "@mantine/hooks";
import { StorageKeys } from "../constants/StorageKeys";

import { Group, Button } from "@mantine/core";

import { TimerIcon } from "./TimerIcon";
import { useLanguage } from "../../../common/language/Language";


import { v4 as uuidv4 } from 'uuid';

export interface MonitoringProps {
    refreshInterval?: number;
    monitoringType?: string;
    timer?: boolean;
    token?: string;
    endExam: () => void;
}

export const useMonitoring = (props?: MonitoringProps) => {
    const lang = useLanguage();    
    const currentClientOS = useOs();

    /* mobile device flag - usage for special mobile rendering*/
    var isMobile = false;
    switch (currentClientOS) {
      case 'ios':
      case 'android':
        isMobile = true;
        break;
    }

    const [monitoringId, setMonitoringId] = useState<string | undefined>(undefined);
    const [events, setEvents] = useState<SecureTestApi.MonitoringEvent[]>([]);

    const { data: monitoring, mutate } = useSWR<SecureTestApi.Monitoring>(
        monitoringId!==undefined && props?.token !== undefined ? "/api/securetest/getmonitoring?id=" + monitoringId : null, 
        (url) => fetcher(url, Http.GET, props?.token),
    {
        refreshInterval: props?.refreshInterval,
        onError: (error) => {
            console.error("Monitoring error ->", error);
        }
    });
    
    const [timeInfo, showTimeInfo] = useState(false);
    const [timerEnded, setTimeEnded] = useState(false);
    
    /* Timer states, storage and and functions */
   
    const [timeLeft, setTimeLeft] = useState<string>("00:00");
    const [timer, setTimer] = useLocalStorage<number>({key: StorageKeys.Timer , defaultValue: 200000});
    
    const interval = useInterval(() => {
       if (props?.timer) {
            if (timer!==undefined && timer > 0){
                setTimer((s) => s - 1);
            }
            else {
                //interval.stop();
            }
       }
    }, 1000);

    const _internalEndExam = () => {        
        addEvent("End", "End Monitoring " + DateTime.now().toISO(), "", SecureTestApi.MonitoringEventTypeCode.End);
        stopMonitoring();
        if (props?.timer) setTimer(0);
        setTimeEnded(true);
        props?.endExam();
    }

    /* effekt that runs for each time tick (second) - endExam (saves) on timer=0 */
    useEffect(() => {
        // console.info("Timer ->", timer);
        if (props?.timer) {
            if (timer!==undefined && timer>0)
                {
                    setTimeLeft(Duration.fromMillis(timer*1000).toFormat("hh:mm"));
                }
                
                if (timer!==undefined &&timer < 120) //last 2 minutes
                {
                    setTimeLeft(Duration.fromMillis(timer*1000).toFormat("hh:mm:ss"));
                }
        
                if (timer===0) {
                    _internalEndExam();
                }
        }
    }, [timer])

    useEffect(() => {
        if (monitoring!==undefined) {
            setEvents(monitoring.events);
            if (monitoring.shouldCloseExam)
            {
                //stop exam
                _internalEndExam();
            }
            if (props?.timer) setTimer(monitoring.timeLeft);
        }
    }, [monitoring]);

    const addEvent = (type: string, text: string, data?: any, typeCode: number = 5) => {
        console.info("Add Event", type, text, data);

        if (monitoring!==undefined && monitoringId!==undefined) {
            
            let event : SecureTestApi.MonitoringEvent = {
                id: uuidv4(),
                eventType: type,
                eventTypeCode: typeCode,
                eventText: text,
                data: JSON.stringify(data),
                eventTime: DateTime.now().toISO()
            }
            monitoring.events.push(event);
            
            switch (props?.monitoringType) {
                case "monitoring":
                    fetcher("/api/securetest/addevent?id=" + monitoring.id, Http.POST, props?.token, event ).then((data) => {
                        console.info("Event added");
                        //mutate();
                    });
                    break;
            }
        }
    }

    const sync = () => {
        if (monitoring!==undefined && monitoringId!==undefined) {
            fetcher("/api/securetest/sync", Http.POST, props?.token, monitoring ).then((data) => {
                console.info("Monitoring synced", data);
                //mutate();
            });
        }
    }

    const startMonitoring = (id: string, runningTimeInSeconds: number) => {
        setMonitoringId(id);
        console.info("Start Monitoring and Time -> ", id);
        if (props?.timer) setTimer(runningTimeInSeconds);
        if (props?.timer) interval.start();
    }

    const refresh = () => {
        if (monitoringId!==undefined) {
            mutate();
        }
    }

    const stopMonitoring = () => {
        if (monitoring!==undefined && monitoringId!==undefined) {
            if (props?.timer) interval.stop();

            addEvent("End", "End Monitoring" + DateTime.now().toISO(), "", SecureTestApi.MonitoringEventTypeCode.End);
            sync();
            setMonitoringId(undefined);
        }
    }

    const renderTimer = () => {
        return (
        <Group justify="end"  className="mr-2">
            {timerEnded? <Button onClick={() => props?.endExam()}>{lang.Text('End Exam')}</Button>:
            <Button variant="subtle" leftSection={<TimerIcon/>} onClick={() =>showTimeInfo(true)}>
                {isMobile ? <span className={timer && timer<120? "text-red-500":""}>{timeLeft}</span>
                : <span className={timer && timer<120 ? "text-red-500":""}>
                <span className="hidden lg:block">{lang.Text('Time left:')}</span>{' '}{timeLeft}</span>}
            </Button>}
            <Button variant="subtle" 
                onClick={() => showTimeInfo(true)}>
                {isMobile? <></> : <span className="hidden md:block">{lang.Text('End Exam')}</span>}
            </Button>
        </Group>
        )
    }

    const renderTimeInfo = () => {
        return (<>
            {lang.Text('Time left:')} {timer && Duration.fromMillis(timer*1000).toFormat("hh:mm:ss")}
        </>)
    }

   
    return {
        startMonitoring,
        stopMonitoring,
        addEvent,
        monitoringEvents: events,
        monitoring,
        refresh,
        setTimer,
        renderTimer,
        timer,
        timeInfo,
        showTimeInfo,
        setTimeEnded,
        timerEnded,
        renderTimeInfo
    }

}