import { Route, Routes, json } from 'react-router-dom';
import { TestCenter } from './pages/Testcenter';
import { Public } from './pages/Public';
import { Exam } from './pages/Exam';
import { Result } from './pages/Result';
import { AccessabilityControl } from './common/accessability/AccessabilityControl';
import { Onboarding } from './pages/Onboarding';
import { ContentRoutes } from './common/content/ContentRoutes';
import { useLanguage } from './common/language/Language';
import { Secure } from './pages/Secure';
import { useHotkeys } from '@mantine/hooks';
import { useNativeClient } from './modules/nativeClient/interop';
import { EndExam } from './pages/EndExam';
import { GlobalAppsettings } from './GlobalSettings';

import packageJson from '../package.json';
import { useEffect, useMemo, useState } from 'react';
import { Http, fetcher } from './common/Fetcher';

export type AppProps = {
};

interface AppProfile {
    id?: string
    name?: string
    version?: string
}

const App = ({}: AppProps) => {
    const lang = useLanguage();
    const { showPanel } = useNativeClient();

    const [appProfile, setAppProfile] = useState<AppProfile>();
    
    useMemo(() => {
        
        
        fetcher<AppProfile>(
            GlobalAppsettings.BaseUrl + '/api/app/AppProfile',
            Http.GET).then((data) => {
                setAppProfile(data);
            }
        );
    }, []);

    useEffect(() => {
        if (appProfile !== undefined) {
            console.log("Package version -> ", packageJson.version);
            console.log("AppProfile version ->", appProfile.version);
            if (appProfile.version !== packageJson.version) {
                console.error("Version mismatch -> App:", appProfile.version, " Package:", packageJson.version);
            }
        }
    }, [appProfile]);

    useHotkeys([
        ['alt+mod+shift+P', () => showPanel()],
       ]);

    return (<>
    <Routes>
        <Route
            key='securetest.public'
            path='/'
            element={ <Public />} 
        />
        <Route
            key="securetest.testcenter"
            path="/testcenter"
            element={ <TestCenter /> }
        />
        <Route
            key='securetest.onboarding'
            path='/onboarding'
            element={ <Onboarding />} 
        /> 
        <Route
            key="securetest.exam"
            path="/exam"
            element={ <Exam /> }
        />
         <Route
            key="securetest.result"
            path="/afterexam"
            element={ <Result /> }
         
        />
        <Route
            key="securetest.result"
            path="/end"
            element={ <EndExam /> }
         
        />  
        <Route path="secure/:testcategoryId" element={<Secure /> } />

        <Route
            key="logout"
            path="/logout"
            element={ <TestCenter /> }
        />

        {/* Routes and pages from Contentful */}
        {/* {ContentRoutes({contentType:'secureTestPage', locale: lang.locale(), preview:false})} */}


    </Routes>
    <div className='fixed right-[40px] bottom-2 text-[11px] text-gray-600'>
        {GlobalAppsettings.AppId}
    </div>
    </>
    );
}

export default App;
