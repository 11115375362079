import { ReactNode, useEffect, useState } from "react";

import { ActionIcon, Alert, Badge, Image, Button, Center, Drawer, Grid, Group, LoadingOverlay, Modal, Paper, SimpleGrid, Slider, Text, 
  Textarea, Title, TypographyStylesProvider, Checkbox, CheckboxProps, Dialog, Loader, ScrollArea, Timeline, Flex, Stack,  Transition, 
  Divider} from "@mantine/core";
import { useDocumentTitle, useForceUpdate, useFullscreen, useHotkeys, useIdle, useInterval, useMediaQuery, useOs, usePageLeave, useViewportSize,} from "@mantine/hooks";

import { RadioGroup } from '@headlessui/react'

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import { ContentBase } from "../../common/content/ContentBase";
import { TimerIcon } from "./components/TimerIcon";

import { SecureTestApi } from './api/SecureTestApi';

import { useLocalStorage } from '@mantine/hooks';

import { DateTime, Duration } from 'luxon';
import { LocalStorageService } from "../../common/StorageService";
import { StorageKeys } from "./constants/StorageKeys";

import { ResultBase } from "../../common/api/common";

import { useLanguage } from "../../common/language/Language";

import {default as LogoWhiteBlue} from '../../common/logos/Logo_whiteblue';
import { renderMarkdown } from "../../common/content/RenderMarkdown";

import { fetcher, Http } from "../../common/Fetcher";

import { useMonitoring } from "./components/useMonitoring";
import { Proctoring } from "./components/Proctoring";
import { Hero } from "../../pages/components/Hero";
import { current } from "immer";
import { usePubsub, useSubscribe } from "../../common/pubsub/usePubsub";
import App from "../../App";
import { TestCategory } from "../../common/api/TestCategory";
import { useFingerPrint } from "../../common/fingerprint/useFingerPrint";

function classNames(...classes:any) {
  return classes.filter(Boolean).join(' ')
}

export interface ISecureTestProps {
  id?: string
  url?: string
  timer?: boolean
  scrollLock?: boolean
  fullScreen?: boolean
  focusLock?: boolean
  focusLockCount?: number
  testCategory? : TestCategory
}

/*

Main SecurTest Component

*/
export const SecureTest = (props: ISecureTestProps) => {

  const lang = useLanguage();

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { toggle: toggleFullScreen, fullscreen } = useFullscreen();
  const [fullscreenMode, setFullscreenMode] = useState<boolean | undefined>(props.fullScreen);
  const [currentQuestionIndex, setCurrentIndex] = useState(1);
  const [totalQuestions, setTotal] = useState(1);
  const [instructions, showInstructions] = useState(false);
  const [feedback, setFeedback] = useState('');
  
  const { fingerprint } = useFingerPrint();

  /* exam session and flags  */
  const [overview, showOverview] = useState(false);
  const [postExam, setPostExam] = useState(false);
  const [endedExam, setEndedExam] = useLocalStorage<boolean>({key: StorageKeys.EndedFlag, defaultValue: false});
  const [resultErrorFlag, setResultErrorFlag] = useState(false);
  const [resultError, setResultError] = useState<string>('');
  const [answeredCount, setAnsweredCount] = useState(0);
  const [focusWarningMode, setfocusWarningMode] = useState(props.focusLock);
  const [focusWarning, showfocusWarning] = useState(false);
  const [currentAnswers, setAnswers] = useState<SecureTestApi.Answer[]>();
  const [selected, setSelected] = useState<SecureTestApi.Answer | undefined>();
  const [multipleChoiceValue, setMultipleChoiceValue] = useState<string[]>([]);

  /*   
  currentSession is stored in Localstorage
  */
  const [currentSession, setCurrentSession] = useLocalStorage<SecureTestApi.Session | undefined>(
      {key: StorageKeys.Session, defaultValue: undefined});
  

  
  const currentClientOS = useOs();

  /* mobile device flag - usage for special mobile rendering*/
  var isMobile = false;
  switch (currentClientOS) {
    case 'ios':
    case 'android':
      isMobile = true;
      break;
  }

  const { height, width } = useViewportSize();
  
  /* Set titlebar to current exam */
  const [title, setTitle] = useState('');
  useDocumentTitle(title);

  /** end exam - called manually by the user or automatically then the timer ends*/
  const endExam = () => {
    let current = currentSession;
    if (current!==undefined) {
      //set flags and dates
      current.exam.timeFinishedUtc = DateTime.now().toISO()
      current.exam.isFinished = true;
      current.exam.answerCount = answeredCount;
      current.isRunning = false;

      //Save to local storage
      setCurrentSession(current);
      setResultErrorFlag(false);
      
      setPostExam(true);

      postExamToBackend();
    }
  }

  const {startMonitoring, stopMonitoring, monitoring, monitoringEvents, 
          addEvent, setTimer, renderTimer, renderTimeInfo,
          timeInfo, showTimeInfo, timerEnded} = useMonitoring(
    {
      refreshInterval: 20000, 
      monitoringType: 'monitoring',
      endExam: endExam,
      token: currentSession?.token,
      timer: true
  });
  
  useEffect(() => { 
    if (currentSession!==undefined) {
      startMonitoring(currentSession.monitoringId, currentSession.durationInMinutes * 60);

      console.info('Load Exam...');
      addEvent("ExamResumed", "Exam resumed: " + currentSession.exam.title, '');

      currentSession.exam.timeStartedUtc = DateTime.now().toISO();
      setTitle("SecureTest:"+ currentSession.exam.title);  

      setTotal(currentSession.exam.questions.length);
      gotoQuestion(currentQuestionIndex);
      updateAnswerCount();

      
      
      setLoading(false);
    }
  }
  , [currentSession]);


   /* keps track of idle time (no iteractions) */
  const idle = useIdle(20000);

  /* Keeps track of focus/unfocus */
  const [unFocusCount, setunFocusCount] = useState(0);
  usePageLeave(() => {
    setunFocusCount((p) => p + 1);
  });

  useEffect(() => {
    if (focusWarningMode){
      console.info('unFocusCount.useEffekt');
      addEvent("Focus", "Exam lost focus" + currentQuestionIndex,currentQuestionIndex );  
      
      if (props.focusLockCount!==undefined){      
        if (unFocusCount > props.focusLockCount)
          {
            showfocusWarning(true);
            addEvent("FocusWarning", "Focus Warning", unFocusCount);
          }
          if (unFocusCount > props.focusLockCount + 2)
          {
            addEvent("Focus", "Ending exam due to Focus Warnings", unFocusCount);
            triggerEndExam();
          }
        }
      }
    }, [unFocusCount]);
  
  /* effekt for change of answers - highlight previous selected answer */
  useEffect(() => { 
    let muliValues: string[] = [];
    
    currentAnswers?.forEach(item => {
      if (item.checked)
      {
        setSelected(item);
        muliValues.push(item.prefix);
      }
    });

    setMultipleChoiceValue(muliValues);

  }, [currentAnswers]);
  
  /* returns imutable new answer array */
  const getAnswers = (index: number) : SecureTestApi.Answer[] => {

    if (currentSession!==undefined) {
      let array:SecureTestApi.Answer[] = new Array<SecureTestApi.Answer>();
      currentSession.exam.questions[index-1].answers.forEach((item => {
        array.push({
          id: item.id,
          checked: item.checked,
          index: item.index,
          prefix: item.prefix,
          text: item.text
        });
      }));
      return array;
    }
    return [];
  }
 
  /* effekt for all state chagnes */
  useEffect(() => { 
    if (currentSession!==undefined)  {
        /* full-screen toogle - defined by props */
        if (fullscreenMode) {
           if (!fullscreen) toggleFullScreen();  
        }
    }
    else
    {
      //if (!endedExam) mutate();
    } 
  });

  /* functions for answering questions and question naviation */
    const updateAnswerCount = () => {
      if (currentSession!==undefined)  { 
      var count = 0;
        currentSession.exam.questions.forEach((item) => {
          if (item.isAnswered) count++;
        }); 
        setAnsweredCount(count);
      }
    }
   
    const gotoPrev = () => {
      if (currentQuestionIndex-1!==0) {
        gotoQuestion(currentQuestionIndex-1);
      }
    }

    const gotoNext = () => {
      if (currentQuestionIndex+1!==totalQuestions+1) {
        gotoQuestion(currentQuestionIndex+1);
      }
    }

    const gotoQuestion = (val: number) => {
      if (val > 0 && val < totalQuestions + 1) {
        setCurrentIndex(val);
        setAnswers(getAnswers(val));
        updateAnswerCount();
        //let currentId = currentSession?.exam.questions[val].id;
        //addEvent("GotoQuestion", "Goto Question " + val , currentId );
      }
    }
   
    const answerQuestion = (toogle: boolean, answer?: SecureTestApi.Answer) => {
      
      let current = currentSession;
      if (current!==undefined && answer!==undefined) {
        
        setSelected(answer);

        let question = current.exam.questions[currentQuestionIndex-1];
        if (question!==undefined) {
          let updated = new Array<SecureTestApi.Answer>();
          
          currentAnswers?.forEach(item => {
            if (item.id===answer.id)
            {
              item.checked = toogle;
            }
            else
            {
              switch (question.questionType) {
                case SecureTestApi.QuestionType.YoucapSinglechoice:
                  item.checked = !toogle;
                  break;
                case SecureTestApi.QuestionType.YoucapMultiplechoice:
                  break;
              }
            }
            updated.push(item);
          });
    
          question.answers = updated;
          question.isAnswered = true;
          question.timeAnswered = DateTime.now().toISO();
        }

        setCurrentSession(current);
        addEvent("AnswerQuestion", "Answer Question " + currentQuestionIndex, answer.id );
        
      }
      
    }

    /* goto *index* question */
    const getQuestion = (index: number) : string => {
      if (currentSession!==undefined) {
        return currentSession.exam.questions[index-1].text;
      }
      return '';
    }
    /* internal answer functions for hotkeys */
    const _internalSetAnswer = (index: number) => {
      if (currentAnswers!==undefined) {
        let a = currentAnswers[index];
        answerQuestion(!a.checked, a);
      }
    }
    /* hotkeys */
    useHotkeys([
      ['alt+p', () => gotoPrev()],
      ['ArrowLeft', () => gotoPrev()], 
      ['alt+n', () => gotoNext()],
      ['ArrowRight', () => gotoNext()],
      ['ctrl+i', () => showInstructions(true)],
      ['alt+t', () => showTimeInfo(true)],
      ['alt+m', () => showOverview(true)],
      ['alt+e', () => setFullscreenMode(!fullscreenMode)],
      ['a', () => _internalSetAnswer(0)],
      ['z', () => _internalSetAnswer(0)],
      ['b', () => _internalSetAnswer(1)],
      ['x', () => _internalSetAnswer(1)],
      ['c', () => _internalSetAnswer(2)],
      ['d', () => _internalSetAnswer(3)],
      ['v', () => _internalSetAnswer(3)],
    ]);

    /* close and reset the exam - should only be called after a successful sav */
    const closeExam = () => {
        setCurrentSession(undefined);
        setAnswers(undefined);
        setAnsweredCount(0);
        setunFocusCount(0);
        setfocusWarningMode(false);
        setTotal(0);
        setCurrentIndex(1);
        /* remove local storage keys */
        LocalStorageService.Remove(StorageKeys.Session);
        LocalStorageService.Remove(StorageKeys.Onboarding);
        LocalStorageService.Remove(StorageKeys.Timer);
        LocalStorageService.Remove(StorageKeys.EndedFlag);
        /* navigate */
        window.open("/afterexam","_self");
    }

    const triggerEndExam = (focusWarning?: boolean) => {
      showfocusWarning(false);
      setfocusWarningMode(false);
      setunFocusCount(10000);
      setTimer(0);
    }

    const postExamToBackend = () => {
      let current = currentSession;
      console.info("postExamToBackend ->",current);

      if (current!==undefined)
      {
        current.timeStampUtc = DateTime.now().toISO();
        current.exam.result = null;

        fetcher<ResultBase<SecureTestApi.Result>>(
          '/api/securetest/PostExam', Http.POST, current?.token, current, false, fingerprint)
        .then((data) => 
        {
          if (data.success)
          {
            setResultErrorFlag(false);
            setEndedExam(true);
          }
          else
          {
            setResultErrorFlag(true);
            setResultError(data.errorMessage);
            console.error(data.errorMessage);
            addEvent("PostExam", "Post Exam Error:", data.errorMessage);
          }
          setPostExam(false);
        }).catch((error)=> {
          setResultErrorFlag(true);
          setResultError(error);
          setPostExam(false);
        });
      }
    }

    useEffect(() => {
      if (endedExam)
      {
        stopMonitoring();
        closeExam();
      }
    },[endedExam]);


    const renderSingleAnswer = () => {
      return( <RadioGroup value={selected} onChange={(answer: SecureTestApi.Answer) => answerQuestion(true, answer)}>
        <RadioGroup.Label className="sr-only"></RadioGroup.Label>
        <div className="bg-white rounded-md -space-y-px">
          {currentAnswers?.map((answer, settingIdx) => (
            <RadioGroup.Option
              key={answer.id}
              value={answer}
              className={({ checked }) => classNames(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === currentAnswers?.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-blue-50 border-blue-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )}
            >
              {({ active, checked }) => (
                <>
                  <span className="">
                    {/* {checked ?
                    <></>
                   <FontAwesomeIcon className="mr-2 border-gray-300 h-7 w-7" color="#0476d9" icon={solid('square-check')} />
                    :<></><FontAwesomeIcon className="mr-2 text-gray-300 h-7 w-7" icon={thin('square')} 
                     */}
                  </span>
                  {renderAnswer(answer, isChecked(answer.index), settingIdx)}
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>);
    }

    const renderAnswer = (answer: SecureTestApi.Answer, checked: boolean, settingIdx : number) => {
      if (currentAnswers)
      return (<> 
      <div className="ml-3 flex flex-col ">
        <span
          className={classNames(checked ? 'text-blue-800 font-bold' : 'text-gray-900', 'block text-md')}
        >
          {answer.prefix}
        </span>
        <span          
          className={classNames(checked ? 'text-blue-700' : 'text-gray-500', 'block text-sm')}
        >
          {renderMarkdown(answer.text,answer.id,"block text-sm whitespace-normal break-words")}
        </span>
      </div>
      </>
      )
    }

    const answerMultiQuestion = (value: string[]) => {
        console.info(value);
        setMultipleChoiceValue(value);

        let current = currentSession;
        if (current!==undefined) {
          let question = current.exam.questions[currentQuestionIndex-1];
          if (question!==undefined) {
            let updated = new Array<SecureTestApi.Answer>();
            currentAnswers?.forEach(item => {
              if (value.includes(item.prefix))
              {
                item.checked = true;
              }
              else
              {
                item.checked = false;
              }
              updated.push(item);
            });

            question.answers = updated;
            question.isAnswered = true;
            question.timeAnswered = DateTime.now().toISO();
          }

          setCurrentSession(current);
          addEvent("AnswerQuestion", "Answer Question " + currentQuestionIndex, currentQuestionIndex );
        }
    }

    const isChecked = (index: number) => {
      switch (index) {
        case 0:
          return multipleChoiceValue.includes('A');
        case 1:
          return multipleChoiceValue.includes('B');
        case 2:
            return multipleChoiceValue.includes('C');
        case 3:
          return multipleChoiceValue.includes('D');
      }
      return false;
    }

   
    const renderMultipleAnswer = () => {
      return(
        <Checkbox.Group className="w-full"
                  value={multipleChoiceValue} 
                  onChange={answerMultiQuestion}
                  //labelProps={}  
                  //offset={0}
                  //orientation="vertical"
                  size="md"
                  inputContainer={(children:any) => <div >{children}</div>}
                  >
        <div className="bg-white rounded-md -space-y-px w-full">
            {currentAnswers?.map((answer, settingIdx) => (
              <Checkbox 
                // icon={CheckboxIcon}
                color={"#0476d9"}
                className={
                  classNames(
                  settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  settingIdx === currentAnswers?.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                  isChecked(answer.index) ? 'bg-blue-50 border-blue-200 z-10' : 'border-gray-200',
                  'relative border p-4 pl-[18px] flex cursor-pointer focus:outline-none '
                )}
                key={answer.id}
                value={answer.prefix}
                label={<>
                  {renderAnswer(answer, isChecked(answer.index), settingIdx)}
                  </>}
              />
            ))}
        </div>  

      </Checkbox.Group>);
    }

    const renderAnswers = (index: number) => {
      switch (currentSession?.exam.questions[index-1].questionType) {
        case SecureTestApi.QuestionType.YoucapSinglechoice:
          return renderSingleAnswer();
        case SecureTestApi.QuestionType.YoucapMultiplechoice:
          return renderMultipleAnswer();
      }
    }

    const getTimelineIcon = (type: string) => {
      return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
    </svg>
    
      // switch (type) {
      //   case "ExamStart":
      //     return <FontAwesomeIcon className="text-violet-700" icon={duotone('star-shooting')} />
      //   case "ExamResume":
      //     return <FontAwesomeIcon className="text-violet-700" icon={duotone('rectangle-history-circle-plus')} />
      //   case "AnswerQuestion":
      //       return <FontAwesomeIcon className="text-violet-700" icon={duotone('list-dots')} />
      // }
      // return <FontAwesomeIcon className="text-violet-700" icon={duotone('list-dots')} />s
      
    }

    let isFinalFocusWarning = false;
    
    // if (props.focusLockCount!==undefined)
    //   isFinalFocusWarning = unFocusCount > (props.focusLockCount + 5);

    let warningCount = 1;
    // if (props.focusLockCount!==undefined)   
    //   warningCount = unFocusCount - props.focusLockCount;

    
    return (
    <>
      <Grid grow gutter={10} columns={24}>
      
      {/* Main */}
      <Grid.Col span={18}
      >
      <Center>
      <Paper shadow="md" 
          className={classNames(
            // fullscreen ? "mx-56": "",
            "mt-2 mb-12 mx-1 xl:max-w-[1200px] xl:min-w-[1200px] md:mx-12 z-50"
          )}
          component="div"
          withBorder p={0}
          >
        <div className="pb-4 min-h-[90vh]" style={{ position: 'relative' }}>
        
          <LoadingOverlay visible={loading} />
             {/* Error */}
            {error ? <Alert className='bg-red-200'
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
                      </svg>
                      } 
                        title={<Title order={4}>{lang.Text('Something went wrong')}</Title>} color="red" radius="md">
                        <Text>{errorMessage}</Text>
                </Alert> : <></>}
            {currentSession!==undefined ? 
            <>
            {/* Header */}
            <Group justify="space-between" className="py-2 sm:px-6 px-2 md:px-6 border-gray-200 border-b">
                <Image
                    w={height> 700 ? 250 : 150}
                        radius="lg"
                        fit="contain"
                        src={props.testCategory?.examLogoUrl}
                        alt="logo"
                    />
                <Text aria-hidden="true" className="text-gray-400 text-sm"><b>{lang.Text('Exam')}:</b> 
                  {'  '}{currentSession?.exam?.title}
                </Text>
            </Group>
           
            <Grid className="py-2">
              <Grid.Col span={3} className="ml-2">
                {isMobile===false ?
                <Button variant="subtle" 
                  className="w-26 lg:w-44"
                  onClick={() =>showOverview(true)}>
                <span className="invisible md:visible w-0 md:w-16">{lang.Text('Question')}</span>
                <span className="px-1">{currentQuestionIndex} of {totalQuestions}</span>
                </Button>:<></>}
              </Grid.Col>
              <Grid.Col 
                  //span={4}
                  span={'auto'}
                >
                  <Group justify="center" >
                  <Button variant="outline" disabled={currentQuestionIndex===1} aria-hidden="true"
                    className="w-14 lg:w-44"
                    onClick={() => gotoPrev()}
                    leftSection={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
                  </svg>}
                    ><span className="sm:invisible md:visible">{lang.Text('Previous')}</span></Button>
                  <Button variant="outline" disabled={currentQuestionIndex===totalQuestions} aria-hidden="true"
                    className="w-14 lg:w-44"
                    onClick={() => gotoNext()}
                    rightSection={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                  </svg>}
                  >{lang.Text('Next')}</Button>
                  </Group>
              </Grid.Col>
              
              <Grid.Col 
                //span={5} 
                span={'auto'}
                >
                {renderTimer()}
            </Grid.Col>
            </Grid>
            
            
              {/* Question */}
             <Text className="mt-8 lg:px-16 xl:px-40 md:px-10 sm:px-6 px-4">
             <div aria-hidden="true" className="text-xl font-bold text-brand py-2">{lang.Text('Question')} {currentQuestionIndex}: </div>
             <TypographyStylesProvider >
                    <ReactMarkdown key={"1"} 
                      children={getQuestion(currentQuestionIndex)}  
                        // components={{
                        // }}
                        remarkPlugins={[remarkGfm]}
                        
                    />
                </TypographyStylesProvider>
             </Text>

             {/* Answers */}
             <div className="mt-8 lg:mx-16 xl:mx-40 md:mx-10 sm:mx-1">
                {renderAnswers(currentQuestionIndex)}
             </div>
          </>: <></>}
        </div>
      </Paper>
      </Center>
      </Grid.Col>
      
      {/* Proctor */}
     {monitoring && monitoring.isRemoteProctoring ?
      <Grid.Col 
        display={"block"} span={5} >
        <Proctoring monitoring={monitoring}/>
      </Grid.Col>:<></>}
      </Grid>

      {/* Bottom */}
      <div className="fixed left-8 right-8 bottom-8 z-50">
           <Group  justify="space-between" >
            <Badge aria-hidden="true" size="lg" variant="filled" color={idle ? 'blue' : 'teal'}>Focus: {idle ? 'idle' : 'active'}</Badge>
            {<span className="fixed bottom-1 text-xs text-gray-700">{currentSession?.exam?.id}</span>}
            <ActionIcon onClick={() => showInstructions(true)} size={"lg"}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </ActionIcon>
          </Group>
        </div>

      {/* Footer */}
      <div className={'fixed left-8 bottom-0 right-0 h-16 pointer-events-none z-1'}>
          <Center className='pb-6'>
          <a href="https://www.youcap.com">
                  <Text size='xs' color="dimmed">Powered By</Text>
                  <LogoWhiteBlue style={{width: '100px', paddingLeft: '7px'}}/>
              </a>
          </Center>
          {/* <Center>
              <Text size={'xs'} color="dimmed">Copyright 2022 YouCap Ltd.</Text>
          </Center> */}
      </div>
      
      {/* Info */}
      <Drawer
          opened={instructions}
          onClose={() => showInstructions(false)}
          title=""
          padding="xl"
          size="xl"
          position="right"
        >
        <div className="flex flex-col absolute inset-6 mt-5">
        <div className="flex-1">
          <ContentBase contentID="6zAJy7EdTWnsXhj0JGxocb"></ContentBase>
        </div>
        <div className="flex flex-row-reverse">
            <Button size="md" color='gray' variant="outline" onClick={() => showInstructions(false)}>{lang.Text('Close')}</Button>
        </div>
        </div>
      </Drawer>
      
      {/* Progress Info */}
      <Drawer
          opened={overview}
          onClose={() => showOverview(false)}
          title=""
          padding="xl"
          size="xl"
          position="left"
        >
        <div className="flex flex-col absolute inset-5 mt-6">
        <div className="flex-1">
          
          <span>{lang.Text('Your progress:')}</span>
          <Slider className="mt-12 py-12 px-8"
            //onChange={(value)=> setCurrentIndex(value)}
            labelAlwaysOn value={answeredCount} max={totalQuestions}
            label={<span>{lang.Text('Answered')} {answeredCount} of {totalQuestions}</span>}
            min={1}
            marks={[
              { value: totalQuestions*0.2, label: '20%' },
              { value: totalQuestions*0.5, label: '50%' },
              { value: totalQuestions*0.8, label: '80%' },
            ]}
            />
          {/* <div>
            Answered: {answeredCount} of {totalQuestions}
          </div> */}

          {/* <ContentBase contentID="6zAJy7EdTWnsXhj0JGxocb"></ContentBase> */}
         
        </div>

        <ScrollArea className="flex-auto  w-full p-4">
            <Timeline className="pl-8">
            {monitoringEvents?.map((event, index) => {
              return (<Timeline.Item key={index}
                title={DateTime.fromISO(event.eventTime).toFormat('yyyy-MM-dd HH:mm:ss')}
                //bulletSize={34}
                
                color="grape"
                bullet={getTimelineIcon(event.eventType)}
              >
                <Text color="dimmed" size="sm">
                  {event.eventText}
                </Text>
              </Timeline.Item>);
            })}
            </Timeline>
          </ScrollArea>
        
        <div className="flex flex-row-reverse">
            <Button size="md" color='gray' variant="outline" onClick={() => showOverview(false)}>{lang.Text('Close')}</Button>
        </div>
        </div>
      </Drawer>
      
      {/* Time Info */}
      <Drawer
          opened={timeInfo}
          onClose={() =>  {
            if (!timerEnded) showTimeInfo(false)
          }}
          title=""
          padding="xl"
          size="xl"
          position="right"
        >
        <div className="flex flex-col absolute inset-5 mt-6 p-4">
        <div className="flex-1">
          <span className="text-md font-bold">{lang.Text('Your progress:')}</span>
          <Slider className="mt-12 py-12 px-8"
            //onChange={(value)=> setCurrentIndex(value)}
            labelAlwaysOn value={answeredCount} max={totalQuestions}
            label={<span>{lang.Text('Answered')} {answeredCount} of {totalQuestions}</span>}
            min={1}
            marks={[
              { value: totalQuestions*0.2, label: '20%' },
              { value: totalQuestions*0.5, label: '50%' },
              { value: totalQuestions*0.8, label: '80%' },
            ]}
            />
         
          <div className="py-4 text-xl font-bold">
            {renderTimeInfo()}
          </div>
         
          <div className="py-3">
            <Button variant="subtle" disabled>{lang.Text('Ask for extended time')}</Button>
          </div>

          <Divider className="my-4" />

          <ContentBase contentID="1nGzCIi4131fTFiTvumG9U">
          
          <div className="mt-8">
            <Center>
              <Button color="green" size="lg" loading={postExam} onClick={() => endExam()}>{lang.Text('Yes, End the exam')}</Button>
            </Center>
          </div>
          </ContentBase>
          
        </div>
        <div className="flex flex-row-reverse">
            {timerEnded? <></>:<Button size="md" color='gray' variant="outline" onClick={() => showTimeInfo(false)}>{lang.Text('Close')}</Button>}
        </div>
        </div>
      </Drawer>
      
      {/* Exam Ended */}
      <Modal 
          fullScreen
          withCloseButton={false} 
          onClose={() => console.info('close')}
          opened={timerEnded}
          trapFocus={true}
          closeOnEscape={false}
          closeOnClickOutside={false}
          padding={0}
        >
        <Hero>
          <div className="text-white py-8 bg-gray-900">
            {postExam? <>
              <Text className="text-white text-xl min-h64">{lang.Text('Saving your exam...')}</Text>
              <Center className='h-[50vh]'><Loader variant='dots'></Loader></Center>
            </>
            :
            <div className="min-h-64">
              {resultErrorFlag ? <>
              <div className="py-12 px-12">
                <p className="text-white text-2xl">{lang.Text('Error:')}</p>
                <p className="text-red-400 truncate text-ellipsis 2">{resultError}</p>
                <div className="py-4">
                  <Center>
                    <Button  color="green" onClick={() => endExam()} className="mr-4">{lang.Text('Try again')}</Button>
                    <Button color="primary"  onClick={closeExam}>{lang.Text('Close the Exam')}</Button>
                  </Center>
                </div>
              </div>
              </> : 
              <Group  className="text-white font-light">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                </svg>
                <Text className="text-white text-xl min-h64">{lang.Text('Exam is stored!')}</Text>
              </Group>}
          </div>}
        </div></Hero>
      </Modal>
      
      {/*  Focus Warning */}
      <Dialog size='full' 
          withCloseButton={false} 
          onClose={() => console.info('close')}
          opened={focusWarning}
          //trapFocus={true}
          position={{ bottom: 20, left: 20 }}
          //closeOnEscape={false}
          //closeOnClickOutside={false}
          className="mx-56"
          >
        <div className="text-3xl">
        {lang.Text('Focus Warning')}  {warningCount}
        </div>
        <ContentBase contentID="3UaOXr5qMskAWsEumZ478T">
          <div className="py-4">
            <Center>
              <Group>
                {isFinalFocusWarning ? <></> :
                <Button color="primary" onClick={() => showfocusWarning(false)}>{lang.Text('Continue exam')}</Button>
                }
                <Button className="ml-4" color="dark" variant="outline" onClick={() => endExam()}>{lang.Text('End exam')}</Button>
              </Group>
            </Center>
          </div>
          </ContentBase>
      </Dialog>

    </>
    );

                 
}


